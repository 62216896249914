@charset "UTF-8";

body {
  //@extend %message;
}

@import "inc/inc";
@import "blocks/video-block/video-block";
@import "blocks/lang/lang";
@import "blocks/header/_header";
@import "blocks/gallery/gallery";
@import "blocks/thumbs/thumbs";
@import "blocks/footer/_footer";
@import "blocks/headline/headline";
@import "blocks/filter/filter";
@import "blocks/pager/pager";
@import "blocks/text/text";
@import "blocks/tags/tags";
@import "blocks/categories/categories";
@import "blocks/statistics-table/statistics-table";
@import "blocks/spec-img/spec-img";
@import "blocks/upload/upload";

.w {
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  @include ie {
    height: 100%;
  }
  & > * {
    flex-shrink: 0;
    flex-grow: 0;
    min-width: 0;
  }
  .page {
    width: 100%;
    max-width: #{$fixed-width}px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 4px #{$page-padding / 2}px 1px;
    @include sm {
      padding: 13px #{$page-padding-mobile / 2}px 1px;
      border-top: 4px solid #C4C4C4;
    }
  }
}
