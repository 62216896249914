.thumbs {
  width: 100%;
  position: relative;
  margin-bottom: 33px;
  .g-w {
    margin-bottom: 7px;
  }
  &-more {
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    .view-more {
      display: block;
      text-transform: uppercase;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  &_cat {
    margin-bottom: 37px;
  }
}
.view-more {
  display: inline-block;
  padding: 0 15px 10px 37px;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #323650;
  transition: color $time;
  &:before {
    //@include sp__8_w;
    background: url(../icons/8.svg) no-repeat 50% 50%;
    content: "";
    display: inline-block;
    width: 14px;
    height: 15px;
    position: absolute;
    top: 1px;
    left: 15px;
    transition: background $time;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 3px;
    background: url("../icons/underline.svg") center / 100% 100%;
    opacity: 0;
    transition: opacity $time, box-shadow $time;
  }
  &:hover {
    @include desktop {
      color: #6852D9;
      &:before {
        background: url(../icons/8_h.svg) no-repeat 50% 50%;
      }
      &:after {
        opacity: 1;
        box-shadow: 0 2px 8px #A498E1;
      }
    }
  }
}