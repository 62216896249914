.footer {
    &-wrapper {
        width: 100%;
        max-width: 100%;
        position: relative;
        margin-top: auto;

    }
    & {
        width: 100%;
        max-width: #{$fixed-width}px;
        margin: 0 auto;
        box-sizing: border-box;
        color: #fff;
        padding: 0 #{$page-padding / 2}px 5px;
        position: relative;
        background: #6852D9;
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        & > * {
            flex-shrink: 0;
            flex-grow: 0;
            min-width: 0;
            display: block;
        }

        @include sm {
            padding: 0 #{$page-padding-mobile / 2}px 5px;
            font-size: 16px;
        }
    }
    > *:last-child {
        margin-bottom: 0;
    }

    .logo {
        display: block;
        margin: 0 auto 3px;
    }
    @include sm {
        flex-direction: column;
        align-items: center;
    }
}

.foot {
    &-lang {
        font-size: 12px;
        line-height: 14px;
        background-color: #ffffff;
        margin: 0 -6px 11px;
        padding: 17px 6px 10px;
        p {
            margin: 0;
        }
        a {
            display: inline-block;
            margin: 0 8.5px 7px;
            color: #323650;
            &:hover {
                @include desktop {
                    color: #6852D9;
                }
            }
            &.active {
                color: #6852D9;
            }
        }
        @include sm {
            margin: 0 -2px 11px;
            padding: 17px 2px 10px;
        }
    }
    &-nav {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        a {
            display: inline-block;
            margin: 0 19.5px 8px;
            color: #ffffff;
            &:hover {
                @include desktop {
                    color: #FF4652;
                }
            }
        }
    }
}

.copyright {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 13px;
}