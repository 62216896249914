.statistics-table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  font-size: 14px;
  line-height: 16px;
  color: #4D4D4D;
  @include sm {
    display: block;
    box-sizing: border-box;
    padding: 0 2px;
    background-color: transparent;
  }
  @include xs {
    padding: 0;
  }
  &-w {
    margin: 0 -6px 27px;
    background-color: #ffffff;
    padding-bottom: 26px;
    @include sm {
      margin: 0 -2px 27px;
      padding: 0;
      background-color: transparent;
    }
  }
  &__head {
    display: table-header-group;
    @include sm {
      display: none;
    }
  }
  &__row {
    display: table-row;
    @include sm {
      flex-grow: 0;
      flex-shrink: 0;
      display: block;
      width: calc(50% - 1px);
      margin: 2px 0;
      padding: 12px 20px;
      background-color: #ffffff;
      box-sizing: border-box;
      border: 1px solid #CCCBD4;
      border-radius: 10px;
      @include xs {
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        &:not(:first-child) {
          border-top: 1px solid #22293c;
        }
      }
    }
  }
  &__th {
    display: table-cell;
    padding: 14px 26px;
    vertical-align: middle;
    border-bottom: 1px solid #CCCBD4;
    white-space: nowrap;
    @include md {
      padding: 13px 12px;
    }
    &_r {
      text-align: center;
    }
  }
  &__body {
    display: table-row-group;
    @include sm {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
    }
  }
  &__td {
    display: table-cell;
    padding: 20px 26px 0;
    vertical-align: top;
    box-sizing: border-box;
    @include md {
      padding: 15px 21px 0;
    }
    @include sm {
      display: block;
      padding: 0;
      position: relative;
      &:before {

      }
    }
    &_m {
      width: 26.2%;
      padding-left: 23px;
      @include sm {
        width: auto;
        min-height: 24px;
        margin-bottom: 11px;
      }
      span {
        display: inline-block;
        margin-right: 20px;
        padding: 0 11px 10px 3px;
        white-space: nowrap;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 3px;
          background: url("../icons/underline.svg") center / 100% 100%;
          opacity: 0;
          transition: opacity $time, box-shadow $time;
        }
        @include md {
          margin-right: 15px;
          padding: 0;
          &:before {
            display: none;
          }
        }
        &:last-child {
          margin: 0;
        }
        &:hover {
          @include desktop {
            &:before {
              opacity: 1;
              box-shadow: 0 2px 8px #A498E1;
            }
            i {
              background: url("../icons/14_h.svg") center / contain no-repeat;
            }
          }
        }
      }
      i {
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 14px;
        margin-right: 10px;
        background: url("../icons/14.svg") center / contain no-repeat;
        transition: background $time;
      }
      a {
        vertical-align: middle;
      }
    }
    &_n {
      padding-left: 18px;

      i {
        width: 10px;
        height: 11px;
        margin-right: 8px;
        background: url("../icons/11.svg") center / contain no-repeat;
        float: left;
        @include sm {
          display: inline-block;
          margin-right: 9px;
        }
      }
      span {
        display: block;
        overflow: hidden;
      }
      a {
        color: #4D4D4D;
        background: none;
      }
      @include sm {
        min-height: 24px;
        margin-bottom: 14px;
      }
    }
    &_s {
      width: 15.8%;
      padding-left: 23px;
      @include sm {
        width: auto;
        min-height: 24px;
        margin-bottom: 24px;
      }
      span {
        display: inline-block;
        padding: 0 7px 10px;
        white-space: nowrap;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 3px;
          background: url("../icons/underline.svg") center / 100% 100%;
          opacity: 0;
          transition: opacity $time, box-shadow $time;
        }
        &:hover {
          @include desktop {
            &:before {
              opacity: 1;
              box-shadow: 0 2px 8px #A498E1;
            }
            i {
              background: url("../icons/5_h.svg") center / contain no-repeat ;
            }
          }
        }
        @include md {
          padding: 0;
          &:before {
            display: none;
          }
        }
      }
      i {
        display: inline-block;
        vertical-align: middle;
        width: 14px;
        height: 8px;
        margin-right: 7px;
        background: url("../icons/5.svg")center / contain no-repeat;
        transition: background $time;
      }
      a {
        vertical-align: middle;
      }
    }
    &_l {
      width: 8%;
      @include sm {
        display: inline-block;
        width: auto;
        min-height: 21px;
        margin-right: 40px;
      }
      span {
        display: inline-block;
        white-space: nowrap;
      }
      i {
        display: inline-block;
        vertical-align: middle;
        width: 13px;
        height: 13px;
        margin-right: 9px;
        background: url("../icons/13.svg")center / contain no-repeat;
        @include sm {
          display: inline-block;
          margin-right: 9px;
        }
      }
    }
    &_r {
      width: 8%;
      color: #FF4652;
      text-align: center;
      @include sm {
        display: inline-block;
        width: auto;
      }
      span {
        display: inline-block;
        white-space: nowrap;
      }
      i {
        display: inline-block;
        vertical-align: middle;
        width: 13px;
        height: 13px;
        margin-right: 12px;
        background: url("../icons/15.svg")center / contain no-repeat;
        @include sm {
          display: inline-block;
          margin-right: 9px;
        }
      }
    }
  }
}