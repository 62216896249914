.pager {
  margin-bottom: 22px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  &-list {
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 0;
    @extend %ulli;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &-item {
      flex-grow: 0;
      flex-shrink: 0;
      @extend %ulli;
      margin: 0 10.5px 2px;
      @include xs {
        margin: 0 2px 2px;
      }
      &-link {
        display: block;
        min-width: 35px;
        height: 41px;
        padding: 10px 12px;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #323650;
        position: relative;
        text-align: center;
        transition: color $time;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 3px;
          background: url("../icons/underline.svg") center / 100% 100%;
          opacity: 0;
          transition: opacity $time, box-shadow $time;
        }
        &:hover {
          @include desktop {
            color: #6852D9;
            &:before {
              opacity: 1;
              box-shadow: 0 2px 8px #A498E1;
            }
          }
        }
        &.cur-pages {
          min-width: 176px;
          height: 50px;
          padding: 0 11px;
          background: #6852D9;
          border-radius: 10px;
          line-height: 50px;
          color: #ffffff;
          display: flex;
          justify-content: space-between;
          @include sm {
            min-width: 0;
          }
          &:before {
            display: none;
          }
          i {
            flex-shrink: 0;
            display: block;
            flex-basis:calc(33.333% - 20px);
            margin: 0 10px;
            font-style: normal;
            text-align: center;
            @include sm {
              margin: 0 5px;
            }
            &:first-child {
              text-align: left;
            }
            &:last-child {
              text-align: right;
            }
          }
          &:hover {
            @include desktop {
              color: #ffffff;
            }
          }
        }
      }
      &_prev,&_next,&_first,&_last {
        .pager-list-item-link {
          text-indent: -99in;
          overflow: hidden;
          position: relative;
        }
      }
      &_prev {
        margin-left: 0;
        .pager-list-item-link {
          width: 37px;
          &:after {
            content: "";
            background: url(../icons/6.svg) no-repeat 50% 50%;
            display: block;
            width: 13px;
            height: 13px;
            position: absolute;
            top: 15px;
            left: 12px;
            transition: background $time;
          }
          &:hover {
            @include desktop {
              &:after {
                background: url(../icons/6_h.svg) no-repeat 50% 50%;
              }
            }
          }
        }
      }
      &_next {
        margin-right: 0;
        .pager-list-item-link {
          &:after {
            background: url(../icons/7.svg) no-repeat 50% 50%;
            content: "";
            display: block;
            width: 13px;
            height: 13px;
            position: absolute;
            top: 15px;
            right: 12px;
            transition: background $time;
          }
          &:hover {
            @include desktop {
              &:after {
                background: url(../icons/7_h.svg) no-repeat 50% 50%;
              }
            }
          }
        }
      }
      &_first {
        margin: 0 20.5px 0 0;
        .pager-list-item-link {
          width: 44px;
          &:after {
            background: url(../icons/9.svg) no-repeat 50% 50%;
            content: "";
            display: block;
            width: 20px;
            height: 12px;
            position: absolute;
            top: 14px;
            left: 12px;
            transition: background $time;
          }
          &:hover {
            @include desktop {
              &:after {
                background: url(../icons/9_h.svg) no-repeat 50% 50%;
              }
            }
          }
        }
      }
      &_last {
        margin: 0 0 0 20.5px;
        .pager-list-item-link {
          width: 44px;
          &:after {
            background: url(../icons/10.svg) no-repeat 50% 50%;
            content: "";
            display: block;
            width: 20px;
            height: 12px;
            position: absolute;
            top: 14px;
            right: 12px;
            transition: background $time;
          }
          &:hover {
            @include desktop {
              &:after {
                background: url(../icons/10_h.svg) no-repeat 50% 50%;
              }
            }
          }
        }
      }
      &.range {
        margin: 0 22.5px 2px;
        @include xs {
          margin: 0 7.5px 2px;
        }
      }
      &.active {
        .pager-list-item-link {
          color: #6852D9;
          &:before {
            opacity: 1;
            box-shadow: 0 2px 8px #A498E1;
          }
        }
      }
    }
  }
}