.categories {
  margin-bottom: 37px;
  &-search {
    margin-bottom: 5px;
    position: relative;
    z-index: 10;
    &-form {
      width: 100%;
      max-width: 450px;
      input {
        display: block;
        width: 100%;
        height: 50px;
        padding: 0 20px;
        background: #fff;
        border: 1px solid #CCCBD4;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        color: #4D4D4D;
        transition: border-color $time;
        @include placeholder {
          color: #4D4D4D;
        }
        &:focus {
          @include placeholder {
            color: transparent;
          }
          border-color: #807F88;
        }
      }
      &-drop {
        //max-height: 0;
        //overflow: hidden;
        //background-color: #23293b;
        //border-radius: 23px;
        //padding-top: 46px;
        //position: absolute;
        //top: 0;
        //right: 11px;
        //left: 0;
        //transition: max-height $time, padding $time;
        &-list {
          //@extend %ulli;
          //padding: 0 32px;
        }
        &__el {
          //@extend %ulli;
          //font-size: 14px;
          //line-height: 19px;
          &:not(:first-child) {
            //margin-top: 12px;
          }
          a {
            //color: #ffffff;
          }
        }
      }
      &.opened {
        .categories-search-form {
          &-tx {
            //background-color: #ffffff;
            //color: #050510;
          }
          &-btn {
            //background: #fff;
            &:before {
              //background: url(../icons/4_d.svg) no-repeat 50% 50%;
              //content: "";
              //display: inline-block;
              //width: 17px;
              //height: 17px;
            }
            &:hover {
              //background: #fff;
              &:before {
                //background: url(../icons/4_d.svg) no-repeat 50% 50%;
                //content: "";
                //display: inline-block;
                //width: 17px;
                //height: 17px;
              }
            }
          }
          &-drop {
            //max-height: 500px;
            //padding: 65px 0 37px;
          }
        }
      }
    }
  }
  &-list {
    column-count: 5;
    column-gap: 37px;
    @include xl {
      column-count: 4;
    }
    @include lg {
      column-count: 3;
    }
    @include md {
      column-count: 2;
    }
    @include sm {
      column-gap: 8px;
    }
    @include xs {
      column-count: 1;
    }
    &__i {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 2px;
      padding: 4px 0 4px 24px;
      box-sizing: border-box;
      position: relative;
      font-size: 16px;
      line-height: 19px;
      @include sm {
        font-size: 14px;
        line-height: 16px;
      }
      @include xs {
        font-size: 16px;
        line-height: 19px;
      }
      &:before {
        background: url(../icons/5.svg) no-repeat 50% 50%;
        content: "";
        display: inline-block;
        width: 14px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: background $time;
      }
      a {
        flex-shrink: 1;
        min-width: 0;
        position: relative;
        font-weight: 500;
        color: #323650;
        text-transform: uppercase;
        span {
          display: block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      i {
        flex-shrink: 0;
        min-width: 60px;
        height: 26px;
        margin-left: 30px;
        padding: 0 10px;
        background: #fff;
        box-sizing: border-box;
        box-shadow: 0 2px 6px #E8E7EF;
        border-radius: 10px;
        font-size: 12px;
        line-height: 26px;
        color: #4D4D4D;;
        font-style: normal;
        text-align: center;
        transition: color $time, background $time;
        @include sm {
          min-width: 52px;
          height: 24px;
          margin-left: 15px;
          padding: 0 6px;
          line-height: 24px;
        }
        @include xs {
          min-width: 60px;
          height: 26px;
          margin-left: 30px;
          padding: 0 10px;
          line-height: 26px;
        }
      }
      > * {
        flex-grow: 0;
        display: block;
        max-width: 100%;
      }
      &:hover {
        @include desktop {
          &:before {
            background-image: url(../icons/5_h.svg);
          }
          a {
            color: #6852D9;
          }
          i {
            color: #ffffff;
            background: #6852D9;
          }
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}