.video-block {
  margin-bottom: 18px;
  &-head {
    margin: 0 -6px 9px;
    padding: 6px;
    background-color: #ffffff;
    @include sm {
      margin: 0 -2px 11px;
    }
    &-title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      h1 {
        flex-shrink: 1;
        flex-grow: 0;
        min-width: 0;
        max-width: 100%;
        margin: 0 11px 0 0;
        padding: 6px 0 7px 31px;
        font-size: 18px;
        line-height: 21px;
        position: relative;
        word-wrap: break-word;
        &:before {
          background: url(../icons/16.svg) 50% 50% no-repeat;
          content: "";
          display: inline-block;
          width: 22px;
          height: 21px;
          position: absolute;
          top: 6px;
          left: 0;
        }
      }
      &-btn {
        flex-grow: 0;
        flex-shrink: 0;
        display: block;
        width: 20px;
        height: 34px;
        cursor: pointer;
        background: url(../icons/12_v.svg) no-repeat 50% 50%;
        position: relative;
        transition: background-color $time;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 3px;
          background: url("../icons/underline.svg") center / 100% 100%;
          opacity: 0;
          transition: opacity $time, box-shadow $time;
        }
        &:hover {
          @include desktop {
            &:before {
              opacity: 1;
              box-shadow: 0 2px 8px #A498E1;
            }
          }
        }
      }
    }
    &-success {
      padding: 10px;
      font-size: 22px;
      color: #4D4D4D;
      text-align: center;
      display: none;
    }
    &-msg {
      width: 100%;
      max-width: 1185px;
      max-height: 0;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      transition: max-height $time, opacity $time, padding $time;
      &.opened {
        max-height: 500px;
        opacity: 1;
        padding: 18px 0 9px;
        overflow: visible;
      }
      &-cont {
        margin-bottom: 9px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        @include sm {
          flex-direction: column;
        }
        &-fw {
          flex-grow: 0;
          flex-shrink: 0;
          width: 202px;
          margin-right: 18px;
          @include sm {
            width: 100%;
            margin: 0 0 18px;
          }
        }
        &-mw {
          flex-grow: 1;
          flex-shrink: 1;
          width: 100%;
          min-width: 0;
        }
      }
      &__p {
        margin-top: 4px;
      }
      &__t {
        width: 100%;
        textarea {
          display: block;
          width: 100%;
          height: 164px;
          padding: 17px 20px;
          background-color: #fff;
          border: 1px solid #CCCBD4;
          border-radius: 10px;
          box-sizing: border-box;
          resize: none;
          overflow: auto;
          font: 12px $font;
          color: #4D4D4D;
          text-transform: uppercase;
          @include placeholder {
            color: #4D4D4D;
          }
          &:focus {
            border-color: #807f88;
            @include placeholder {
              color: transparent;
            }
          }
        }
      }
      &-btn {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: stretch;
        button {
          display: block;
          flex-grow: 0;
          flex-shrink: 0;
          min-width: 100px;
          height: 40px;
          padding: 0 20px;
          border: none;
          background: #fff;
          border-radius: 10px;
          font-size: 14px;
          color: #4D4D4D;
          box-shadow: 0 2px 6px #E8E7EF;
          transition: background $time, color $time;
          &:hover {
            @include desktop {
              color: #fff;
              background: #6852D9;
            }
          }
        }
      }
    }
  }
  &-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    @include lg {
      flex-direction: column;
      justify-content: flex-start;
    }
    &-main {
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
      max-width: 1274px;
      min-width: 0;
      overflow: hidden;
      .thumbs-more {
        @include md {
          display: none;
        }
      }
    }
    &-side {
      flex-grow: 0;
      flex-shrink: 0;
      width: 300px;
      margin-left: 10px;
      padding-top: 71px;
      @include lg {
        width: 100%;
        margin: 0;
        padding: 0;
      }
      .spec-img {
        &-list {
          margin: 0;
          flex-wrap: nowrap;
          flex-direction: column;
          justify-content: flex-start;
          @include lg {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
          }
        }
        a {
          margin: 0;
          &:not(:first-child){
            margin-top: 14px;
          }
          @include lg {
            margin: 10px 50px;
          }
          @include md {
            margin: 10px 25px;
          }
          @include sm {
            margin: 10px;
          }
          @include xs {
            margin: 10px 0;
          }
        }
      }
    }
  }
  &-tags {
    max-height: 64px;
    margin-right: -22px;
    margin-bottom: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    transition: max-height $time;
    span {
      flex-grow: 0;
      flex-shrink: 0;
      order: 10;
      display: block;
      margin-right: 21px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
      position: relative;
      transition: opacity $time;
      &.m-r {
        margin-right: 32px;
      }
      &.t-i {
        padding-left: 36px;
        i {
          display: block;
          width: 22px;
          height: 22px;
          background: #22293c linear-gradient(to left, #0788f7 0%, #19afe8 100%);
          border-radius: 5px;
          position: absolute;
          top: 0;
          left: 0;
          &:before {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%,-50%);
          }
          &.t-i {
            &_s {
              &:before {
                //@include sp__9_d;
                background: url(../icons/9_d.svg) no-repeat 50% 50%;
                content: "";
                display: inline-block;
                width: 11px;
                height: 11px;
              }
            }
            &_r {
              &:before {
                //@include sp__10;
                background: url(../icons/10.svg) no-repeat 50% 50%;
                content: "";
                display: inline-block;
                width: 17px;
                height: 16px;
                width: 12px;
                height: 12px;
                background-size: contain;
              }
            }
            &_n {
              &:before {
                //@include sp__13;
                background: url(../icons/13.svg) no-repeat 50% 50%;
                content: "";
                display: inline-block;
                width: 19px;
                height: 14px;
                width: 12px;
                height: 9px;
                background-size: contain;
              }
            }
            &_i {
              &:before {
                //@include sp__14;
                background: url(../icons/14.svg) no-repeat 50% 50%;
                content: "";
                display: inline-block;
                width: 15px;
                height: 20px;
                width: 10px;
                height: 12px;
                background-size: contain;
              }
            }
          }
        }
      }
      &.hdn {
        order: 30;
        opacity: 0;
        visibility: hidden;
      }
      a {
        color: #ffffff;
        &:hover {
          @include desktop {
            color: #8394af;
          }
        }
      }
    }
    .sh-h {
      flex-grow: 0;
      flex-shrink: 0;
      order: 20;
      display: block;
      padding: 0 6px;
      font-size: 16px;
      color: $link-color;
    }
    &.opened {
      max-height: 600px;
      span {
        &.hdn {
          order: 10;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &-added {
    margin-bottom: 17px;
    padding-left: 36px;
    color: #ffffff;
    position: relative;
    > u {
      display: block;
      width: 22px;
      height: 22px;
      background: #22293c linear-gradient(to left, #0788f7 0%, #19afe8 100%);
      border-radius: 5px;
      color: #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      &:before {
        //@include sp__11;
        background: url(../icons/11.svg) no-repeat 50% 50%;
        content: "";
        display: inline-block;
        width: 19px;
        height: 19px;
        width: 13px;
        height: 13px;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%,-50%);
      }
    }
    span {
      color: $link-color;
    }
  }
  &-video {
    min-height: 360px;
    position: relative;
    padding: 56.25% 0 0;
    box-sizing: border-box;
    overflow: hidden;
    > img, iframe, video {
      width: 100%;
      height: 100%;
      display: block;
      box-sizing: border-box;
      border: none;
      position: absolute;
      top: 0;
      right: 0;
    }
    .video-js {
      width: 100% !important;
      height: 100% !important;
      position: absolute !important;
      top: 0;
      right: 0;
      .vgs-big-play-button {
        height: 3em;
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        line-height: 3em;
      }
    }
  }
  &-voting {
    padding: 12px 7px 26px;
    position: relative;
    &-msg {
      max-width: 100%;
      min-width: 300px;
      background-color: #fff;
      padding: 8px 14px;
      border: 1px solid #CCCBD4;
      visibility: hidden;
      opacity: 0;
      margin: 0 0 5px;
      overflow: hidden;
      color: #4D4D4D;
      position: absolute;
      bottom: 100%;
      left: 0;
      transform: translateY(100%);
      box-sizing: border-box;
      text-align: center;
      transition: .2s all 2s;
      &.a {
        opacity:1;
        visibility:visible;
        transition:all .4s;
        transform:translateY(0)
      }
    }
  }
  &-thumbs {
    width: 100%;
    margin-bottom: 6px;
    overflow: hidden;
    .g {
      @include md {
        flex-grow: 1;
        flex-shrink: 0;
        width: auto;
        margin: 0;
        flex-wrap: nowrap;
      }
      &-w {
        @include md {
          padding-bottom: 30px;
          margin-bottom: -30px;
          overflow-x: scroll;
          overflow-y: hidden;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: stretch;
          -webkit-overflow-scrolling: touch;
        }
      }
      &__i {
        width: calc(16.666% + -5px);
        margin-right: 5px;
        margin-bottom: 7px;
        @include md {
          width: 190px;
          margin: 0 3px 0 0;
        }
        @include sm {
          width: 180px;
        }
        @include xs {
          width: 140px;
        }
        &:nth-child(n + 7) {
          display: none;
          @include md {
            display: block;
          }
        }
        p, h3 {
          max-height: 36px;
          margin-top: 7px;
          font-size: 14px;
          line-height: 18px;
        }
        &_can-play {
          a {
            &:hover {
              .g__i-img {
                &:after {
                  position: absolute;
                  right: 50%;
                  bottom: 50%;
                  transform: translate(50%, 50%);
                  content: "";
                  box-sizing: border-box;
                  border-left: 34.64px solid #fff;
                  border-top: 20px solid transparent;
                  border-bottom: 20px solid transparent;
                  width: 40px;
                  height: 40px;
                  opacity: .6;
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
    }
    &-wr {
      &.show-all {
        .g {
          &__i {
            &:nth-child(n + 7) {
              display: block;
            }
          }
        }
      }
    }
  }
}

.checkbox_main {
  position: absolute;
  opacity: 0;
  margin: 0;
  & + label {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 32px;
    cursor: pointer;
    color: #4D4D4D;
    line-height: 22px;
    &:before,&:after {
      content: '';
      position: absolute;
      display: block;
    }
    &:before {
      width: 22px;
      height: 22px;
      background-color: #fff;
      border: 1px solid #CCCBD4;
      box-sizing: border-box;
      top: 0;
      left: 0;
      transition: border-color $time;
    }
    &:after {
      width: 10px;
      height: 10px;
      background-color: transparent;
      top: 6px;
      left: 6px;
      transition: background-color $time;
    }
  }
  &:checked {
    & + label {
      &:before {
        border-color: #807f88;
      }
      &:after {
        background-color: #6852D9;
      }
    }
  }
}

.vote {
  height: 24px;
  padding-left: 33px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url("../icons/vote.svg") no-repeat;
    position: absolute;
    top: 0;
    left: 0;
  }
  &-like, &-dislike {
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 28px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4D4D4D;
    text-transform: uppercase;
    transition: color $time;
  }
  &-like {
    order: 1;
    &:hover {
      @include desktop {
        color: #6852D9;
      }
    }
  }
  &-dislike {
    order: 30;
    &:hover {
      @include desktop {
        color: #FF4652;
      }
    }
  }
  &-r {
    order: 20;
    padding: 0 16px;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #533EBF;
    position: relative;
    top: 1px;
    small {
      font-size: 14px;
    }
  }
}

.video-block-video>video.video-js{visibility:hidden}
.video-block-video .video-js{width:100%;height:100%;position:absolute!important;left:0;top:0}
.video-block-video .video-js .vjs-fullscreen-control.vjs-control.vjs-button{-webkit-order:20;-ms-flex-order:20;order:20}
.video-block-video .video-js .vjs-big-play-button{height:3em;top:50%;left:50%;margin:0;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);border-radius:50%;line-height:3em}
.video-block-video .video-js .vjs-big-play-button .vjs-icon-placeholder{font-size:2em}
.video-block-video .video-js .vjs-poster{background-size:cover}