.tags {
  margin-bottom: 62px;
 &-cat {
   width: 100%;
   overflow: hidden;
   margin-bottom: 6px;
   &-list {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: flex-start;
     align-items: flex-start;
     align-content: flex-start;
     margin-right: -4px;
     padding-top: 7px;
     &__el {
       flex-grow: 0;
       flex-shrink: 0;
       display: block;
       margin: 0 4px 6px 0;
       padding: 0 8px 10px;
       font-size: 14px;
       line-height: 16px;
       color: #323650;
       position: relative;
       transition: color $time;
       &:before {
         content: '';
         position: absolute;
         right: 0;
         bottom: 0;
         left: 0;
         height: 3px;
         background: url("../icons/underline.svg") center / 100% 100%;
         opacity: 0;
         transition: opacity $time, box-shadow $time;
       }
       &:hover {
         @include desktop {
           color: #6852D9;
           &:before {
             opacity: 1;
             box-shadow: 0 2px 8px #A498E1;
           }
         }
       }
       &.active {
         color: #6852D9;
         &:before {
           opacity: 1;
           box-shadow: 0 2px 8px #A498E1;
         }
       }
     }
   }
 }
  &-l {
    width: 100%;
    overflow: hidden;
    margin-bottom: 12px;
    .h {
      margin-bottom: 17px;
    }
    &-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      margin-right: -4px;
      &__el {
        flex-grow: 0;
        flex-shrink: 0;
        display: block;
        margin: 3px 4px 0 0;
        padding: 3px 8px 10px;
        font-size: 14px;
        line-height: 16px;
        color: #323650;
        position: relative;
        transition: color $time;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 3px;
          background: url("../icons/underline.svg") center / 100% 100%;
          opacity: 0;
          transition: opacity $time, box-shadow $time;
        }
        &:hover {
          @include desktop {
            color: #6852D9;
            &:before {
              opacity: 1;
              box-shadow: 0 2px 8px #A498E1;
            }
          }
        }
        &.active {
          color: #6852D9;
          &:before {
            opacity: 1;
            box-shadow: 0 2px 8px #A498E1;
          }
        }
        &-ic {
          width: 22px;
          height: 22px;
          padding: 0;
          background: url("../icons/17.svg") 50% 50% no-repeat;
          &:before {
            display: none;
          }
          &_v1 {
            background-image: url(../icons/18.svg);
          }
          &_v2 {
            background-image: url(../icons/19.svg);
          }
        }
      }
      > span {
        flex-grow: 0;
        flex-shrink: 0;
        display: block;
        font-size: 14px;
        line-height: 16px;
        margin: 3px 0 0 12px;
        padding: 3px 0 10px;
        span {
          color: #6852D9;
        }
      }
    }
    &-w {
      margin-bottom: 7px;
    }
  }
}