.lang-menu {
    //position: relative;
    font-family: $font;
    font-size: 12px;
    font-weight: 500;
    //line-height: 14px;
    line-height: 16px;
    color: #ffffff;
    text-transform: uppercase;
    z-index: 20;
    //order: 50;
    flex-shrink: 0;
    flex-grow: 0;
    /*&:before {
        position: absolute;
        right: 0;
        max-width: 100%;
        width: 560px;
        content: '';
        top: 50%;
        bottom: -1px;
        display: none;
    }*/
    //@include md {
        //margin-left: 20px;
        // order: 9;
    //}
    @include sm {
        position: static;
        margin-left: auto;
    }
    &__label {
        display: block;
        position: relative;
        padding-right: 16px;
        cursor: pointer;
        white-space: nowrap;
        min-width: 22px;
        @extend %set;
        //img {
        //    display: block;
        //    width: 22px;
        //    float: left;
        //    margin-right: 10px;
        //    position: relative;
        //    top: 1px;
        //    @include md {
        //        margin-right: 0;
        //        top: 0;
        //    }
        //}
        .flag {
            display: block;
            float: left;
            margin-right: 10px;
            @include md {
                margin-right: 0;
                top: 0;
            }
        }
        &:before {
            //@include sp__1;
            background: url("../icons/1.svg") no-repeat 50% 50%;
            content: "";
            display: block;
            width: 8px;
            height: 4px;
            position: absolute;
            //top: 4px;
            top: 5px;
            right: 0;
            @include md {
                //top: 5px;
                top: 6px;
            }
        }
        @include md {
            font-size: 0;
            line-height: 0;
            text-indent: -99in;
            padding-right: 31px;
        }
        /* @include sm {

         }*/
        &:hover {
            @include desktop {
                color: #ffffff;
            }
        }
    }
    &__list {
        position: absolute;
        top: 100%;
        margin-left: -10px;
        background: #6852D9;
        border-radius: 0 0 10px 10px;
        width: 530px;
        box-sizing: border-box;
        padding: 0 10px;
        max-height: 0;
        overflow: hidden;
        transition: max-height $time, padding $time;
        ul,
        li {
            @extend %ulli;
        }
        ul {
            column-width: 145px;
            column-gap: 35px;
            font-size: 0;
            line-height: 0;
        }
        li {
            position: relative;
            overflow: hidden;
            display: inline-block;
            width: 100%;
            font-size: 12px;
            //line-height: 14px;
            line-height: 16px;
            padding: 7px 0 8px;
            //img {
            //    display: block;
            //    width: 22px;
            //    float: left;
            //    margin-right: 10px;
            //    border-radius: 2px;
            //}
            .flag {
                display: block;
                width: 22px;
                float: left;
                margin-right: 10px;
                border-radius: 2px;
            }
            a {
                color: #fff;
                transition: color $time;
                &:hover {
                    @include desktop {
                        color: #FF4652;
                    }
                }
            }
            &.active {
                a {
                    color: #FF4652;
                    text-decoration: underline;
                }
            }
        }
        //@include md {
            //top:calc(100% + 20px);
        //}
        @include sm {
            width: auto;
            margin: 0;
            top: 60px;
            right: 0;
            left: 0;
        }
    }
    #lang-menu__check {
        position: absolute;
        left: -99in;
        top: -99in;
    }
    /* &:hover &__list {
         max-height: 500px;
         padding: 8px 10px;
     }
     &:hover {
         &:before {
             display: block;
         }
     }*/
    #lang-menu__check:checked ~ &__label {
        color: #ffffff;
    }
    #lang-menu__check:checked ~ &__list {
        max-height: 884px;
        padding: 5px 10px 9px;
        @include sm {
            max-height: 442px;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
    /*&:hover {
        &:before {
            display: block;
        }
    }*/
}