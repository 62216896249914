@mixin fixed-width {
     @media (min-width: $fixed-width) {
        @content;
    }
}
@mixin xxl {
    @media (min-width: $xxl) {
        @content;
    }
}
@mixin xl {
    @media (max-width: #{$xxl - 1px}) {
        @content;
    }
}
@mixin lg {
    @media (max-width: #{$xl - 1px}) {
        @content;
    }
}
@mixin lg_only {
    @media (min-width: #{$lg}) and (max-width: #{$xl - 1px}) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: #{$lg}) {
        @at-root body:not(.mobile) & {
            @content;
        }
    }
}
@mixin md {
    @media (max-width: #{$lg - 1px}) {
        @content;
    }
}
@mixin md_only {
    @media (min-width: #{$md}) and (max-width: #{$lg - 1px}) {
        @content;
    }
}
@mixin md_up {
    @media (min-width: #{$md}) {
        @content;
    }
}
@mixin sm_only {
    @media (min-width: #{$sm}) and (max-width: #{$md - 1px}) {
        @content;
    }
}
@mixin sm_up {
    @media (min-width: #{$sm}) {
        @content;
    }
}
@mixin sm {
    @media (max-width: #{$md - 1px}) {
        @content;
    }
}
@mixin xs {
    @media (max-width: #{$sm - 1px}) {
        @content;
    }
}
@mixin xxs {
    @media (max-width: #{$xs}) {
        @content;
    }
}
