* {
    outline: none !important;
}
html, body, div, ul, ol, li, h1, h2, h3, h4, h5, h6, dd, dt, dl, table, tr, td, th, p, span, strong, small, a, img, form {
    margin: 0;
    padding: 0;
}
html {
    height: 100%;
    font-size: 62.5%;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    overflow-x: hidden;
    overflow-y: auto;

    -webkit-overflow-scrolling: touch;
}
body {
    font: 400 14px/20px $font;
    color: $text-color;
    background-color: $body_bg;
    overflow: visible;
    position: relative;
    height: 100%;

    text-size-adjust: 100%;
    -webkit-overflow-scrolling: auto;
}
img {
    border: none;
}
a {
    cursor: pointer;
    color: $link-color;
    transition: color $time;
    text-decoration: none;
}
a:hover {
    @include desktop {
        color: #6852D9;
        text-decoration: none;
    }
}
p {
    margin-bottom: 20px;
}
p small {
    font-size: 0.8em;
}
h1, h2, h3, h4, h5, h6 {
    font: 400 18px/1.2 $font;
    color: #4D4D4D;
    margin-bottom: 15px;
}
h1 {
    font-size: 24px;
}
h3 {
    font-size: 17px;
}
h4 {
    font-size: 16px;
}
h5 {
    font-size: 15px;
}
h6 {
    font-size: 14px;
}
input::-ms-clear {
    display: none;
}
input[type='submit'], input[type='button'], button {
    cursor: pointer;
    font-family: $font;
}
ul, ol {
    padding-left: 30px;
    margin-bottom: 15px;
}
ul li {
    list-style: disc outside;
}
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
    padding: 0 !important;
    border: 0 none !important;
}