.h {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 10px 6px;
    margin: 0 -6px 10px;
    background-color: #ffffff;
    //width: 100%;
    @include sm {
        flex-direction: column;
        align-items: stretch;
        margin: 0 -2px 8px;
        padding: 12px 6px 11px;
    }
    &__t {
        flex-shrink: 1;
        flex-grow: 0;
        min-width: 0;
        display: block;
        margin-right: auto;
        padding: 2px 20px 2px 0;
        max-width: 100%;
        position: relative;
        word-wrap: break-word;
        box-sizing: border-box;
        overflow: hidden;
        align-self: stretch;
        @include sm {
            width: 100%;
            padding-right: 0;
        }
        * {
            display: inline;
        }
        &-h {
            margin-right: 8px;
            position: relative;
            padding-left: 31px;

            &,
            * {
                color: #4D4D4D;
                font-size: 18px;
                font-weight: 400;
                @include sm {
                    //font-size: 18px;

                }
            }
            &:before {
                background: url(../icons/16.svg) no-repeat 50% 50%;
                content: "";
                display: inline-block;
                width: 22px;
                height: 21px;
                position: absolute;
                top: 0;
                left: 0;
            }

            @include sm {
                display: block;
                margin-bottom: 2px;
            }
        }
    }

    &__f {
        flex-shrink: 0;
        flex-grow: 0;
        min-width: 0;
        padding: 0 8px;
        @include sm {
            padding: 23px 0 0;
        }
        .filter {
            &-dropdown {
                right: 0;
                left: auto;
            }
        }
    }
}