@import "icons";
%set:after {
    height: 0;
    overflow: hidden;
    clear: both;
    font-size: 0;
    line-height: 0;
    display: block;
    content: '';
}
%ulli {
    list-style: outside none none;
    padding: 0;
    margin: 0;
}
%message {
    &:before {
        //display: none;
        position: fixed;
        left: 0;
        bottom: 50px;
        font-size: 20px;
        font-weight: bold;
        padding: 5px;
        z-index: 999;
        color: #000;
        text-shadow: 0 0 5px #fff;
        @include xxl {
            content: '> #{$xxl}';
        }
        @include xl {
            content: '#{$xl}';
        }
        @include lg {
            content: '#{$lg}';
        }
        @include md {
            content: '#{$md}';
        }
        @include sm {
            content: '#{$sm}';
        }
        @include xs {
            content: '#{$xs}';
        }
    }
}
/*.mbn {
    margin-bottom: 0 !important;
}
.mtn {
    margin-top: 0 !important;
}*/
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}
@mixin noscroll {
    &::-webkit-scrollbar {
        width: 0;
    }

    & {
        -ms-overflow-style: none;
    }

}
@mixin ie {
    @media screen and (min-width: 0 \0
    ) and (min-resolution: +72dpi) {
        @content;
    }
}
@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin before_bg {
    z-index: -1;
    position: absolute;
    width: 99in;
    right: 50%;
    top: 0;
    bottom: 0;
    transform: translateX(50%);
    //background-color: $bg;
    content: '';
}
%btn {
    text-decoration: none;
    max-width: 100%;
    text-align: center;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    background-color: #f2bf4a;
    transition: all $time;
    cursor: pointer;
    color: rgb(88, 39, 25);
    font-family: $font;
    font-size: 14px;
    font-weight: 400;
    line-height: 34px;
    padding: 0 22px;
    text-transform: uppercase;
}
%btn {
    &:hover,
    &.a {
        @extend %btn_hover;
    }
}
%btn_hover,
%btn_active {

    background-color: #cf4016;
    color: rgb(255, 255, 255);
}
/*%btn_active {
    box-shadow: 0 0 5px $link-color;
}*/
%btn_2 {
    min-width: 38px;
    height: 35px;
    border-radius: 3px;
    padding: 0 5px;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    color: #353a3e;
    font: normal 400 14px / 33px $font;
    text-align: center;
    transition: all $time;
    &:hover,
    &.a {
        @extend %btn_2_hover;
    }
}
%btn_2_hover {
    box-shadow: 0 0 5px #c4c4c4;
    color: $link-color;
}
%tag {
    display: inline-block;
    font: 400 10px / 15px $font;
    max-width: 100%;
    @include text-overflow;
    height: 19px;
    border: 1px solid rgb(207, 64, 22);
    background-color: rgb(207, 64, 22);
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    font-size: 10px;
    line-height: 17px;
    text-transform: uppercase;
    padding: 0 5px;
    margin: 0 1px 1px 0;
    transition: all $time;
    &:hover {
        @extend %tag_hover;
    }
}
%tag_hover {
    border: 1px solid rgb(207, 64, 22);
    background-color: rgb(25, 25, 25);
    color: rgb(207, 64, 22);
}
@mixin gallery_width($gallery-item-width: 316, $i: 5, $swiper: false, $free_space: 0) {
    width: calc(#{(100 / $i )}% + #{$gallery-item-margin * -1}px);
    $i_default: $i;
    $k: 1;

    @while $i > 0 {
        $scroll-w: 0;

        @if ($gallery-item-width*$i + $gallery-item-margin * ($i)) < $md {
            $page-padding: $page-padding-mobile;
        }

        @if $swiper == true {
            @if (($gallery-item-width*$i + $gallery-item-margin * ($i) + $scroll-w + $page-padding + $gallery-item-width/4) > $xs) {

                @if ($gallery-item-width*$i + $gallery-item-margin * ($i) + $scroll-w + $page-padding + $gallery-item-width/4) <= $lg {
                    @media (max-width: #{$gallery-item-width*$i + $gallery-item-margin * ($i) + $scroll-w + $page-padding}px) {
                        width: calc(#{(100 / $i )}% + #{$gallery-item-margin * -1}px);
                    }
                    @media (max-width: #{$gallery-item-width*$i + $gallery-item-margin * ($i ) + $scroll-w + $page-padding  + $gallery-item-width/4}px) {
                        @at-root .mobile & {
                            width: calc(#{(100 / ($i + 1/4))}% + #{$gallery-item-margin * -1}px);
                        }

                    }
                } @else {

                    @media (max-width: #{$gallery-item-width*$i + $gallery-item-margin * ($i) + $scroll-w + $page-padding + $free_space}px) {
                        width: calc(#{(100 / $i )}% + #{$gallery-item-margin * -1}px);
                    }

                }
            }
        } @else {
            @if ($gallery-item-width*$i + $gallery-item-margin * ($i - 1) + $scroll-w + $page-padding + $free_space) >= ($gallery-item-width) {
                @media (max-width: #{$gallery-item-width*$i + $gallery-item-margin * ($i) + $scroll-w + $page-padding + $free_space}px) {
                    width: calc(#{(100 / $i )}% + #{$gallery-item-margin * -1}px);
                }
            }
        }

        $i: $i - 1;
    }

}
