.header {
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
    position: relative;
    z-index: 200;
    @include sm {
        margin-bottom: 9px;
        background-color: #ffffff;
    }
    &:before {
        content: '';
        height: 60px;
        background: #6852D9;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: none;
        @include sm {
            display: block;
        }
    }
}
header {
    width: 100%;
    max-width: #{$fixed-width}px;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    position: relative;
    z-index: 200;
    padding: 0 10px;

    @include sm {
        flex-wrap: wrap;
        padding: 0 10px 9px;
        background-color: transparent;
    }
    & > * {
        min-width: 0;
        max-width: 100%;
    }
}
.logo {
    display: block;
    margin-right: 22px;
    @include md {
        //margin-right: 20px;
    }
    @include sm {
        //margin-right: 4px;
    }
    img {
        display: block;
    }
}
.logo,
nav,
.nbttn {
    flex-shrink: 0;
    flex-grow: 0;
}
nav {
    order: 30;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    margin: 10px 20px 0 0;
    padding: 0;
    box-sizing: border-box;
    a {
        display: block;
        flex-shrink: 0;
        flex-grow: 0;
        min-width: 0;
        color: #323650;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        position: relative;
        margin-left: 5px;
        padding: 0 15px 10px;
        text-transform: uppercase;
        transition: color $time;
        &:before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 3px;
            background: url("../icons/underline.svg") center / 100% 100%;
            opacity: 0;
            transition: opacity $time, box-shadow $time;
            @include md {
                display: none;
            }
        }
        &.active {
            color: #6852D9;
            &:before {
                opacity: 1;
                box-shadow: 0 2px 8px #A498E1;
            }
            @include md {
                color: #FF4652;
            }

        }
        &:hover {
            @include desktop {
                color: #6852D9;
                &:before {
                    opacity: 1;
                    box-shadow: 0 2px 8px #A498E1;
                }
            }
        }
        @include lg_only {
            font-size: 13px;
            line-height: 15px;
            padding: 0 10px 8px;
        }
        @include md {
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
        }
        &.n-upl {
            width: 24px;
            margin-left: 15px;
            padding: 0 3px 10px;
            text-indent: -99in;
            @include md {
                width: auto;
                margin: 0;
                padding: 10px 12px;
                text-indent: 0;
            }
            &:after {
                content: '';
                width: 24px;
                height: 19px;
                background: url("../icons/2.svg") center no-repeat;
                position: absolute;
                top: -1px;
                left: 3px;
                @include md {
                    display: none;
                }
            }
        }
    }
    @include md {
        min-height: 0;
        max-height: 0;
        padding: 0;
        margin: 0;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 60px;
        background-color: #6852D9;
        width: 100%;
        align-items: flex-start;
        overflow: hidden;
        transition: padding $time, max-height $time;
        z-index: 20;
        a {

            & {
                align-self: stretch;
                padding: 10px 12px;
                margin: 0 0 10px;
            }

        }

        @at-root #nbtn:checked ~ & {
            max-height: 500px;
            padding: 22px 0 55px;
        }
    }
}
#nbtn {
    display: none;
}
.nbttn {
    background: url(../icons/3_1.svg) no-repeat 50% 50%;
    width: 31px;
    height: 31px;
    display: none;
    margin-left: 34px;
    order: 60;
    @include md {
        display: block;
    }
    @include sm {
        margin: 0 0 0 auto;
        background-image: url(../icons/3.svg);
    }
}
.search {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    order: 20;
    width: 100%;
    margin-left: auto;
    position: relative;
    & > * {
        min-width: 0;
        display: block;
        box-sizing: border-box;
        margin: 0;
    }
    & > input[type="text"] {
        appearance: none;
        flex-shrink: 1;
        flex-grow: 1;
        padding: 0 54px 0 18px;
        color: #4D4D4D;
        font-family: $font;
        font-size: 12px;
        font-weight: 400;
        height: 54px;
        background-color: #fff;
        border: 1px solid #CCCBD4;
        border-radius: 10px;
        text-transform: uppercase;
        transition: border-color $time;
        @include placeholder {
            color: #4D4D4D;
        }
        &:focus {
            border-color: #807F88;
            color: #4D4D4D;
            @include placeholder {
                color: transparent;
            }
        }
        @include sm {
            padding: 0 77px 0 23px;
        }
    }
    & > [type="submit"] {
        appearance: none;
        /*flex-shrink: 0;
        flex-grow: 0;*/
        border: none;
        width: 54px;
        height: 54px;
        padding: 0;
        border-radius: 10px;
        //font-family: $font;
        //font-size: 16px;
        //font-weight: 400;
        background: none;
        position: absolute;
        top: 0;
        right: 0;
        //transition: background $time;
        &:before {
            //@include sp__4;
            background: url(../icons/4.svg) no-repeat 50% 50%;
            content: "";
            display: block;
            width: 22px;
            height: 23px;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%,-50%);
            transition: background $time;
        }
    }
    @include sm {
        max-width: 100%;
        width: 100%;
        order: 90;
        margin: 9px 0 0;
    }
}

.user-menu {
    flex-grow: 0;
    flex-shrink: 0;
    order: 50;
    margin: 0 20px 0 17px;
    @include lg_only {
        margin: 0 15px 0 12px;
    }
    @include md {
        margin-left: 10px;
    }
    &-list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        @extend %ulli;
    }
    &-item {
        @extend %ulli;
        flex-shrink: 0;
        flex-grow: 0;
        &:not(:first-child) {
            margin-left: 5px;
        }
        &-link {
            display: block;
            //height: 46px;
            //padding: 0 24px;
            //box-sizing: border-box;
            //background: #0e101d;
            //border: 2px solid #fff;
            //border-radius: 23px;
            //font-size: 16px;
            //line-height: 42px;
            //color: #fff;
            //transition: color $time, background $time, border-color $time;
            &:hover {
                @include desktop {
                    //color: #8394af;
                    //border-color: transparent;
                    //background: #0e101d;
                }
            }
            &_upload {
                //padding: 0 28px;
                //border: none;
                //line-height: 46px;
                //background: #23293b linear-gradient(to left, #0788f7 0%, #19afe8 100%);

                @include md {
                    //@include sp__2;
                    //background: url(../icons/2.svg) no-repeat 50% 50%;
                    //content: "";
                    //width: 31px;
                    //height: 21px;
                    //display: block;
                    //padding: 0;
                    //text-indent: -99in;
                }
                &:before {
                    //content: '';
                    //position: absolute;
                    //top: calc(100% + 5px);
                    //right: 0;
                    //left: 0;
                    //height: 3px;
                    //background: transparent;
                    //border-radius: 2px;
                    //transition: background $time, box-shadow $time;
                }
                &:hover {
                    //background: #0e101d;
                    @include md {
                        //@include sp__2;
                        //background: url(../icons/2.svg) no-repeat 50% 50%;
                        //content: "";
                        //width: 31px;
                        //height: 21px;
                        //display: block;
                    }
                    @include desktop {
                        &:before {
                            //background: #6852D9;
                            //box-shadow: 0 2px 8px #A498E1;
                        }
                    }
                }
            }
        }
    }
}

.logo-lang {
    flex-grow: 0;
    flex-shrink: 0;
    order: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    background: #6852D9;
    border-radius: 0 10px 10px 0;
    margin: 0 15px 0 -10px;
    padding: 16px 18px 16px 10px;
    position: relative;
    @include lg_only {
        margin-right: 10px;
    }
    @include sm {
        position: static;
        margin: 0 15px 0 0;
        padding: 16px 0;
        background: transparent;
        border-radius: 0;
    }
}