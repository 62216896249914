.g-w {
  //margin: 24px;
  @media (max-width: 335px) {
    //margin: 0 #{-$page-padding-mobile / 2}px;
    width: 100%;
    overflow: hidden;
  }
}

.g {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  margin-right: -#{$gallery-item-margin}px;
  @media (max-width: 335px) {
    margin-right: -3px;
  }

  &__i {
    flex-shrink: 0;
    flex-grow: 0;
    min-width: 0;
    box-sizing: border-box;
    position: relative;
    @include gallery_width($gallery-item-w, $gallery-items-in-line, false);
    margin-right: #{$gallery-item-margin}px;
    margin-bottom: #{$gallery-item-margin  * 3 + 1}px;
    z-index: 10;
    //background-color: #ffffff;
    //transition: box-shadow $time;

    &-img {
      position: relative;
      display: block;
      width: 100%;
      padding-top: $gallery-item-h/$gallery-item-w * 100%;
      box-sizing: border-box;
      border-radius: 10px;
      overflow: hidden;
      transition: all $time;

      .video-loader {
        display: none;
      }

      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -99;
        opacity: 0;
        transition: opacity $time;

        &.loadcl {
          opacity: 1;
        }
      }

      &_slider-end {
        &:hover,
        .mobile & {
          &:after {
            position: absolute;
            right: 50%;
            bottom: 50%;
            transform: translate(50%, 50%);
            content: "";
            box-sizing: border-box;
            border-left: 34.64px solid #fff;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            width: 40px;
            height: 40px;
            opacity: .6;
            pointer-events: none;
          }
        }
      }

      &_video-hover {
        img {
          z-index: 5;
        }

        video {
          opacity: 1;
          z-index: 10;
        }

        .video-loader {
          display: block;
          z-index: 8;
        }

        &.video_loaded {

          &:before {

            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            content: '';
            transition: all $time;
            background-color: #000;
            z-index: 7;
          }
        }

      }

      &.video_loaded {
        .video-loader {
          display: none;
        }
      }
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    p {
      max-width: 100%;
      max-height: 36px;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 5px;
      margin: 7px 0 0;
      transition: all $time;
      //@include text-overflow;
      //flex-shrink: 1;
      color: $text-color;
      font-family: $font;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;

    }

    &-title {
      margin: 9px 0 0;
      padding: 0 5px;
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      color: $text-color;
      position: relative;
      transition: all $time;
      span {
        display: block;
        width: 100%;
        @include text-overflow;

      }
    }

    &-time,
    &-info {
      font-size: 12px;
      line-height: 22px;
      color: #ffffff;
      position: absolute;
      top: -24px;
      right: 3px;
      margin-top: $gallery-item-h / $gallery-item-w * 100%;
      padding: 0 8px;
      height: 22px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 15;
      @media (max-width: 600px) {
        height: 20px;
        padding: 0 4px;
        line-height: 20px;
      }
    }
    &-info {
      padding: 0 6px;
    }

    &-report {
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 10px;
      background: rgba(0,0,0,.7) url("../icons/12.svg") center no-repeat;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 15;
      transition: background $time;
      &:hover {
        @include desktop {
          background: #6852D9 url("../icons/12.svg") center no-repeat;
        }
      }
    }

    &:hover {
      & {
        z-index: 20;
        //box-shadow: 0px 4px 4px #ECEDF2;
      }

      p,
      .g__i-title {
        color: #6852D9;
      }

    }
    @media (max-width: 335px) {
      width: 100%;
      margin: 0 0 17px;
    }
    &_nl {
      &:hover {
        p,
        h3 {
          color: #6852D9;
        }
      }
    }
  }
}

.video-loader {
  &,
  &:before,
  &:after {
    background: #ffffff;
    animation: loader 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }

  & {
    color: #ffffff;
    text-indent: -9999em;
    font-size: 11px;
    animation-delay: -0.16s;
    position: absolute;
    margin: 0;
    right: 15px;
    top: -3px;
    transform: scale(.3);
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: '';
  }

  &:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }

  &:after {
    left: 1.5em;
  }

}

@keyframes loader {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}