.filter {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  &-list {
    flex-shrink: 0;
    display: flex;
    @include sm {
      width: 100%;
    }
    @include xs {
      justify-content: space-between;
    }
    a {
      flex-shrink: 0;
      display: block;
      margin: 0 6px 0 5px;
      padding: 0 12px 10px;
      font-weight: 500;
      line-height: 16px;
      color: #323650;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      transition: color $time;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 3px;
        background: url("../icons/underline.svg") center / 100% 100%;
        opacity: 0;
        transition: opacity $time, box-shadow $time;
      }
      &:hover {
        @include desktop {
          color: #6852D9;
          &:before {
            opacity: 1;
            box-shadow: 0 2px 8px #A498E1;
          }
        }
      }
      &.active {
        color: #6852D9;
        &:before {
          opacity: 1;
          box-shadow: 0 2px 8px #A498E1;
        }
      }
      @include sm {
        margin: 0 2px;
      }
    }
  }
}