.upload {
  margin-bottom: 140px;
  @include sm {
    margin-bottom: 60px;
  }
  .h {
    margin-bottom: 2px;
  }
 &-block {
   position: relative;
   min-height: 100px;
   margin: 2px -6px 20px;
   padding: 3% 8%;
   background-color: #ffffff;
   @include sm {
     margin: 2px -2px 20px;
   }
   &-form {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%,-50%);
     text-align: center;
     &-btn {
       padding-top: 53px;
       display: inline-block;
       font-weight: 500;
       font-size: 14px;
       line-height: 16px;
       color: #4D4D4D;
       cursor: pointer;
       position: relative;
       text-align: center;
       text-transform: uppercase;
       transition: all $time;
       &:before {
         content: '';
         display: block;
         width: 48px;
         height: 38px;
         background: url("../icons/upload.svg") no-repeat;
         position: absolute;
         top: 0;
         left: 50%;
         transform: translateX(-50%);
       }
       &:hover {
         @include desktop {
           color: #6852D9;
         }
       }
     }
     p {
       margin: 7px 0 0;
       line-height: 16px;
     }
   }
 }
  > p {
    margin: 20px 0;
  }
  &-fields {
    margin-bottom: 28px;
    &__el {
      margin-bottom: 3px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      @include md {
        flex-direction: column;
        align-items: flex-start;
      }
      &-input {
        flex-grow: 0;
        flex-shrink: 0;
        width: 100%;
        max-width: 450px;
        &_t {
          max-width: 671px;
          margin-bottom: 34px;
        }
        &_v {
          max-width: 671px;
        }
        input, textarea {
          display: block;
          width: 100%;
          box-sizing: border-box;
          height: 50px;
          padding: 0 20px;
          background-color: #fff;
          border: 1px solid #CCCBD4;
          border-radius: 10px;
          font: 12px $font;
          color: #4D4D4D;
          text-transform: uppercase;
          transition: border-color $time;
          @include placeholder {
            color: #4D4D4D;
          }
          &:focus {
            border-color: #807f88;
            @include placeholder {
              color: transparent;
            }
          }
        }
        textarea {
          height: 183px;
          padding: 17px 20px;
          resize: none;
          overflow: auto;
        }
      }
      &-n {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 100%;
        box-sizing: border-box;
        padding: 5px 17px;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        &_er {
          color: #FF4652;
        }
      }
      &_btn {
        max-width: 671px;
        margin: 8px 0 27px;
        justify-content: flex-end;
      }
    }
    &-save {
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      max-width: 100%;
      min-width: 100px;
      height: 40px;
      padding: 0 20px;
      border: none;
      background: #fff;
      border-radius: 10px;
      font-size: 14px;
      color: #4D4D4D;
      box-shadow: 0 2px 6px #E8E7EF;
      transition: background $time, color $time;
      &:hover {
        @include desktop {
          color: #fff;
          background: #6852D9;
        }
      }
      span {
        display: block;
        width: 100%;
        @include text-overflow;
      }
    }
    > p {
      max-width: 671px;
    }
  }
  .message_error {
    color: #18ade9;
  }
}

.upload-active-image {
  width: 25%;
  float: left;
  margin: 0 20px 0 0;
}

.upload-active-image > div {
  position: relative;
  padding: 56.25% 0 0 0;
  background: #fff;
}

.upload-active-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.upload-active-data {
  overflow: hidden;
}

.upload-active-status {
  font-weight: 700;
}

.upload-active-bar {
  height: 10px;
  margin: 30px 0;
  background: #DCDCDC;
  border-radius: 2px;
}

.upload-active-bar span {
  height: 10px;
  display: block;
  background: linear-gradient(90deg, #6852D9 0%, #FF4652 100%);
  border-radius: 2px;
}
.text-success {
  color: #4D4D4D;
}

.text-danger {
  color: #4D4D4D;
}
#upload_success .upload-form{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}