$time: .2s;
$font: 'Roboto', sans-serif;

$body_bg: #F6F6F6;
$text-color: #4D4D4D;
$link-color: #323650;

$xxl: 1440px;
$xl: 1280px;
$lg: 1024px;
$md: 768px;
$sm: 480px;
$xs: 320px;


$scroll-w: 15;
$page-padding: 12;
$page-padding-mobile: 4;

$gallery-items-in-line: 5;
$gallery-item-margin: 3;
$gallery-item-w: 316;
$gallery-item-h: 178;

$fixed-width: $page-padding + ($gallery-item-margin * ($gallery-items-in-line - 1)) + ($gallery-item-w * $gallery-items-in-line);