// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$ar-svg-name: 'ar';
$ar-svg-x: 0px;
$ar-svg-y: 0px;
$ar-svg-offset-x: 0px;
$ar-svg-offset-y: 0px;
$ar-svg-width: 22px;
$ar-svg-height: 16px;
$ar-svg-total-width: 152px;
$ar-svg-total-height: 116px;
$ar-svg-image: '../img/sprite.png';
$ar-svg: (0px, 0px, 0px, 0px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'ar',);
$bg-svg-name: 'bg';
$bg-svg-x: 26px;
$bg-svg-y: 0px;
$bg-svg-offset-x: -26px;
$bg-svg-offset-y: 0px;
$bg-svg-width: 22px;
$bg-svg-height: 16px;
$bg-svg-total-width: 152px;
$bg-svg-total-height: 116px;
$bg-svg-image: '../img/sprite.png';
$bg-svg: (26px, 0px, -26px, 0px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'bg',);
$cs-svg-name: 'cs';
$cs-svg-x: 0px;
$cs-svg-y: 20px;
$cs-svg-offset-x: 0px;
$cs-svg-offset-y: -20px;
$cs-svg-width: 22px;
$cs-svg-height: 16px;
$cs-svg-total-width: 152px;
$cs-svg-total-height: 116px;
$cs-svg-image: '../img/sprite.png';
$cs-svg: (0px, 20px, 0px, -20px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'cs',);
$da-svg-name: 'da';
$da-svg-x: 26px;
$da-svg-y: 20px;
$da-svg-offset-x: -26px;
$da-svg-offset-y: -20px;
$da-svg-width: 22px;
$da-svg-height: 16px;
$da-svg-total-width: 152px;
$da-svg-total-height: 116px;
$da-svg-image: '../img/sprite.png';
$da-svg: (26px, 20px, -26px, -20px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'da',);
$de-svg-name: 'de';
$de-svg-x: 52px;
$de-svg-y: 0px;
$de-svg-offset-x: -52px;
$de-svg-offset-y: 0px;
$de-svg-width: 22px;
$de-svg-height: 16px;
$de-svg-total-width: 152px;
$de-svg-total-height: 116px;
$de-svg-image: '../img/sprite.png';
$de-svg: (52px, 0px, -52px, 0px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'de',);
$el-svg-name: 'el';
$el-svg-x: 52px;
$el-svg-y: 20px;
$el-svg-offset-x: -52px;
$el-svg-offset-y: -20px;
$el-svg-width: 22px;
$el-svg-height: 16px;
$el-svg-total-width: 152px;
$el-svg-total-height: 116px;
$el-svg-image: '../img/sprite.png';
$el-svg: (52px, 20px, -52px, -20px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'el',);
$en-svg-name: 'en';
$en-svg-x: 0px;
$en-svg-y: 40px;
$en-svg-offset-x: 0px;
$en-svg-offset-y: -40px;
$en-svg-width: 22px;
$en-svg-height: 16px;
$en-svg-total-width: 152px;
$en-svg-total-height: 116px;
$en-svg-image: '../img/sprite.png';
$en-svg: (0px, 40px, 0px, -40px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'en',);
$es-svg-name: 'es';
$es-svg-x: 26px;
$es-svg-y: 40px;
$es-svg-offset-x: -26px;
$es-svg-offset-y: -40px;
$es-svg-width: 22px;
$es-svg-height: 16px;
$es-svg-total-width: 152px;
$es-svg-total-height: 116px;
$es-svg-image: '../img/sprite.png';
$es-svg: (26px, 40px, -26px, -40px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'es',);
$fi-svg-name: 'fi';
$fi-svg-x: 52px;
$fi-svg-y: 40px;
$fi-svg-offset-x: -52px;
$fi-svg-offset-y: -40px;
$fi-svg-width: 22px;
$fi-svg-height: 16px;
$fi-svg-total-width: 152px;
$fi-svg-total-height: 116px;
$fi-svg-image: '../img/sprite.png';
$fi-svg: (52px, 40px, -52px, -40px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'fi',);
$fr-svg-name: 'fr';
$fr-svg-x: 78px;
$fr-svg-y: 0px;
$fr-svg-offset-x: -78px;
$fr-svg-offset-y: 0px;
$fr-svg-width: 22px;
$fr-svg-height: 16px;
$fr-svg-total-width: 152px;
$fr-svg-total-height: 116px;
$fr-svg-image: '../img/sprite.png';
$fr-svg: (78px, 0px, -78px, 0px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'fr',);
$he-svg-name: 'he';
$he-svg-x: 78px;
$he-svg-y: 20px;
$he-svg-offset-x: -78px;
$he-svg-offset-y: -20px;
$he-svg-width: 22px;
$he-svg-height: 16px;
$he-svg-total-width: 152px;
$he-svg-total-height: 116px;
$he-svg-image: '../img/sprite.png';
$he-svg: (78px, 20px, -78px, -20px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'he',);
$hi-svg-name: 'hi';
$hi-svg-x: 78px;
$hi-svg-y: 40px;
$hi-svg-offset-x: -78px;
$hi-svg-offset-y: -40px;
$hi-svg-width: 22px;
$hi-svg-height: 16px;
$hi-svg-total-width: 152px;
$hi-svg-total-height: 116px;
$hi-svg-image: '../img/sprite.png';
$hi-svg: (78px, 40px, -78px, -40px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'hi',);
$hu-svg-name: 'hu';
$hu-svg-x: 0px;
$hu-svg-y: 60px;
$hu-svg-offset-x: 0px;
$hu-svg-offset-y: -60px;
$hu-svg-width: 22px;
$hu-svg-height: 16px;
$hu-svg-total-width: 152px;
$hu-svg-total-height: 116px;
$hu-svg-image: '../img/sprite.png';
$hu-svg: (0px, 60px, 0px, -60px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'hu',);
$id-svg-name: 'id';
$id-svg-x: 26px;
$id-svg-y: 60px;
$id-svg-offset-x: -26px;
$id-svg-offset-y: -60px;
$id-svg-width: 22px;
$id-svg-height: 16px;
$id-svg-total-width: 152px;
$id-svg-total-height: 116px;
$id-svg-image: '../img/sprite.png';
$id-svg: (26px, 60px, -26px, -60px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'id',);
$it-svg-name: 'it';
$it-svg-x: 52px;
$it-svg-y: 60px;
$it-svg-offset-x: -52px;
$it-svg-offset-y: -60px;
$it-svg-width: 22px;
$it-svg-height: 16px;
$it-svg-total-width: 152px;
$it-svg-total-height: 116px;
$it-svg-image: '../img/sprite.png';
$it-svg: (52px, 60px, -52px, -60px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'it',);
$ja-svg-name: 'ja';
$ja-svg-x: 78px;
$ja-svg-y: 60px;
$ja-svg-offset-x: -78px;
$ja-svg-offset-y: -60px;
$ja-svg-width: 22px;
$ja-svg-height: 16px;
$ja-svg-total-width: 152px;
$ja-svg-total-height: 116px;
$ja-svg-image: '../img/sprite.png';
$ja-svg: (78px, 60px, -78px, -60px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'ja',);
$ko-svg-name: 'ko';
$ko-svg-x: 0px;
$ko-svg-y: 80px;
$ko-svg-offset-x: 0px;
$ko-svg-offset-y: -80px;
$ko-svg-width: 22px;
$ko-svg-height: 16px;
$ko-svg-total-width: 152px;
$ko-svg-total-height: 116px;
$ko-svg-image: '../img/sprite.png';
$ko-svg: (0px, 80px, 0px, -80px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'ko',);
$ms-svg-name: 'ms';
$ms-svg-x: 26px;
$ms-svg-y: 80px;
$ms-svg-offset-x: -26px;
$ms-svg-offset-y: -80px;
$ms-svg-width: 22px;
$ms-svg-height: 16px;
$ms-svg-total-width: 152px;
$ms-svg-total-height: 116px;
$ms-svg-image: '../img/sprite.png';
$ms-svg: (26px, 80px, -26px, -80px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'ms',);
$nb-svg-name: 'nb';
$nb-svg-x: 52px;
$nb-svg-y: 80px;
$nb-svg-offset-x: -52px;
$nb-svg-offset-y: -80px;
$nb-svg-width: 22px;
$nb-svg-height: 16px;
$nb-svg-total-width: 152px;
$nb-svg-total-height: 116px;
$nb-svg-image: '../img/sprite.png';
$nb-svg: (52px, 80px, -52px, -80px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'nb',);
$nl-svg-name: 'nl';
$nl-svg-x: 78px;
$nl-svg-y: 80px;
$nl-svg-offset-x: -78px;
$nl-svg-offset-y: -80px;
$nl-svg-width: 22px;
$nl-svg-height: 16px;
$nl-svg-total-width: 152px;
$nl-svg-total-height: 116px;
$nl-svg-image: '../img/sprite.png';
$nl-svg: (78px, 80px, -78px, -80px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'nl',);
$pl-svg-name: 'pl';
$pl-svg-x: 104px;
$pl-svg-y: 0px;
$pl-svg-offset-x: -104px;
$pl-svg-offset-y: 0px;
$pl-svg-width: 22px;
$pl-svg-height: 16px;
$pl-svg-total-width: 152px;
$pl-svg-total-height: 116px;
$pl-svg-image: '../img/sprite.png';
$pl-svg: (104px, 0px, -104px, 0px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'pl',);
$pt-svg-name: 'pt';
$pt-svg-x: 104px;
$pt-svg-y: 20px;
$pt-svg-offset-x: -104px;
$pt-svg-offset-y: -20px;
$pt-svg-width: 22px;
$pt-svg-height: 16px;
$pt-svg-total-width: 152px;
$pt-svg-total-height: 116px;
$pt-svg-image: '../img/sprite.png';
$pt-svg: (104px, 20px, -104px, -20px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'pt',);
$ro-svg-name: 'ro';
$ro-svg-x: 104px;
$ro-svg-y: 40px;
$ro-svg-offset-x: -104px;
$ro-svg-offset-y: -40px;
$ro-svg-width: 22px;
$ro-svg-height: 16px;
$ro-svg-total-width: 152px;
$ro-svg-total-height: 116px;
$ro-svg-image: '../img/sprite.png';
$ro-svg: (104px, 40px, -104px, -40px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'ro',);
$ru-svg-name: 'ru';
$ru-svg-x: 104px;
$ru-svg-y: 60px;
$ru-svg-offset-x: -104px;
$ru-svg-offset-y: -60px;
$ru-svg-width: 22px;
$ru-svg-height: 16px;
$ru-svg-total-width: 152px;
$ru-svg-total-height: 116px;
$ru-svg-image: '../img/sprite.png';
$ru-svg: (104px, 60px, -104px, -60px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'ru',);
$sk-svg-name: 'sk';
$sk-svg-x: 104px;
$sk-svg-y: 80px;
$sk-svg-offset-x: -104px;
$sk-svg-offset-y: -80px;
$sk-svg-width: 22px;
$sk-svg-height: 16px;
$sk-svg-total-width: 152px;
$sk-svg-total-height: 116px;
$sk-svg-image: '../img/sprite.png';
$sk-svg: (104px, 80px, -104px, -80px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'sk',);
$sl-svg-name: 'sl';
$sl-svg-x: 0px;
$sl-svg-y: 100px;
$sl-svg-offset-x: 0px;
$sl-svg-offset-y: -100px;
$sl-svg-width: 22px;
$sl-svg-height: 16px;
$sl-svg-total-width: 152px;
$sl-svg-total-height: 116px;
$sl-svg-image: '../img/sprite.png';
$sl-svg: (0px, 100px, 0px, -100px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'sl',);
$sr-svg-name: 'sr';
$sr-svg-x: 26px;
$sr-svg-y: 100px;
$sr-svg-offset-x: -26px;
$sr-svg-offset-y: -100px;
$sr-svg-width: 22px;
$sr-svg-height: 16px;
$sr-svg-total-width: 152px;
$sr-svg-total-height: 116px;
$sr-svg-image: '../img/sprite.png';
$sr-svg: (26px, 100px, -26px, -100px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'sr',);
$sv-svg-name: 'sv';
$sv-svg-x: 52px;
$sv-svg-y: 100px;
$sv-svg-offset-x: -52px;
$sv-svg-offset-y: -100px;
$sv-svg-width: 22px;
$sv-svg-height: 16px;
$sv-svg-total-width: 152px;
$sv-svg-total-height: 116px;
$sv-svg-image: '../img/sprite.png';
$sv-svg: (52px, 100px, -52px, -100px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'sv',);
$th-svg-name: 'th';
$th-svg-x: 78px;
$th-svg-y: 100px;
$th-svg-offset-x: -78px;
$th-svg-offset-y: -100px;
$th-svg-width: 22px;
$th-svg-height: 16px;
$th-svg-total-width: 152px;
$th-svg-total-height: 116px;
$th-svg-image: '../img/sprite.png';
$th-svg: (78px, 100px, -78px, -100px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'th',);
$tr-svg-name: 'tr';
$tr-svg-x: 104px;
$tr-svg-y: 100px;
$tr-svg-offset-x: -104px;
$tr-svg-offset-y: -100px;
$tr-svg-width: 22px;
$tr-svg-height: 16px;
$tr-svg-total-width: 152px;
$tr-svg-total-height: 116px;
$tr-svg-image: '../img/sprite.png';
$tr-svg: (104px, 100px, -104px, -100px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'tr',);
$zh-svg-name: 'zh';
$zh-svg-x: 130px;
$zh-svg-y: 0px;
$zh-svg-offset-x: -130px;
$zh-svg-offset-y: 0px;
$zh-svg-width: 22px;
$zh-svg-height: 16px;
$zh-svg-total-width: 152px;
$zh-svg-total-height: 116px;
$zh-svg-image: '../img/sprite.png';
$zh-svg: (130px, 0px, -130px, 0px, 22px, 16px, 152px, 116px, '../img/sprite.png', 'zh',);
$spritesheet-width: 152px;
$spritesheet-height: 116px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($ar-svg, $bg-svg, $cs-svg, $da-svg, $de-svg, $el-svg, $en-svg, $es-svg, $fi-svg, $fr-svg, $he-svg, $hi-svg, $hu-svg, $id-svg, $it-svg, $ja-svg, $ko-svg, $ms-svg, $nb-svg, $nl-svg, $pl-svg, $pt-svg, $ro-svg, $ru-svg, $sk-svg, $sl-svg, $sr-svg, $sv-svg, $th-svg, $tr-svg, $zh-svg,);
$spritesheet: (152px, 116px, '../img/sprite.png', $spritesheet-sprites,);

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
    width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
    height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
    $sprite-offset-x: nth($sprite, 3);
    $sprite-offset-y: nth($sprite, 4);
    background-position: $sprite-offset-x $sprite-offset-y;
}

@mixin sprite-image($sprite) {
    $sprite-image: nth($sprite, 9);
    background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
    @include sprite-position($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
    @each $sprite in $sprites {
        $sprite-name: nth($sprite, 10);
        .lang_#{$sprite-name} {
            @include sprite($sprite);
        }
    }
}

.flag {
    display: inline-block;
    width: 22px;
    height: 16px;
    background-image: url('../img/sprite.png');
}

@include sprites($spritesheet-sprites);
