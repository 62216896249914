.spec-img {
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;
  &-list {
    margin: 0 -54px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    @include md {
      margin: 0 -25px;
    }
    @include sm {
      margin: 0;
    }
  }
  a {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 300px;
    height: 250px;
    overflow: hidden;
    margin: 10px 54px;
    @include md {
      margin: 10px 25px;
    }
    @include sm {
      margin: 10px;
    }
    @include xs {
      margin: 10px 0;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  &_v {
    a {
      height: 100px;
    }
  }
}