@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

.lang-menu__label:after {
  height: 0;
  overflow: hidden;
  clear: both;
  font-size: 0;
  line-height: 0;
  display: block;
  content: '';
}

.lang-menu__list ul,
.lang-menu__list li, .user-menu-list, .user-menu-item, .pager-list, .pager-list-item {
  list-style: outside none none;
  padding: 0;
  margin: 0;
}

/*.mbn {
    margin-bottom: 0 !important;
}
.mtn {
    margin-top: 0 !important;
}*/
/*%btn_active {
    box-shadow: 0 0 5px $link-color;
}*/
.flag {
  display: inline-block;
  width: 22px;
  height: 16px;
  background-image: url("../img/sprite.png");
}

.lang_ar {
  background-position: 0px 0px;
}

.lang_bg {
  background-position: -26px 0px;
}

.lang_cs {
  background-position: 0px -20px;
}

.lang_da {
  background-position: -26px -20px;
}

.lang_de {
  background-position: -52px 0px;
}

.lang_el {
  background-position: -52px -20px;
}

.lang_en {
  background-position: 0px -40px;
}

.lang_es {
  background-position: -26px -40px;
}

.lang_fi {
  background-position: -52px -40px;
}

.lang_fr {
  background-position: -78px 0px;
}

.lang_he {
  background-position: -78px -20px;
}

.lang_hi {
  background-position: -78px -40px;
}

.lang_hu {
  background-position: 0px -60px;
}

.lang_id {
  background-position: -26px -60px;
}

.lang_it {
  background-position: -52px -60px;
}

.lang_ja {
  background-position: -78px -60px;
}

.lang_ko {
  background-position: 0px -80px;
}

.lang_ms {
  background-position: -26px -80px;
}

.lang_nb {
  background-position: -52px -80px;
}

.lang_nl {
  background-position: -78px -80px;
}

.lang_pl {
  background-position: -104px 0px;
}

.lang_pt {
  background-position: -104px -20px;
}

.lang_ro {
  background-position: -104px -40px;
}

.lang_ru {
  background-position: -104px -60px;
}

.lang_sk {
  background-position: -104px -80px;
}

.lang_sl {
  background-position: 0px -100px;
}

.lang_sr {
  background-position: -26px -100px;
}

.lang_sv {
  background-position: -52px -100px;
}

.lang_th {
  background-position: -78px -100px;
}

.lang_tr {
  background-position: -104px -100px;
}

.lang_zh {
  background-position: -130px 0px;
}

* {
  outline: none !important;
}

html, body, div, ul, ol, li, h1, h2, h3, h4, h5, h6, dd, dt, dl, table, tr, td, th, p, span, strong, small, a, img, form {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
  width: 100%;
  max-width: 100%;
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  font: 400 14px/20px "Roboto", sans-serif;
  color: #4D4D4D;
  background-color: #F6F6F6;
  overflow: visible;
  position: relative;
  height: 100%;
  text-size-adjust: 100%;
  -webkit-overflow-scrolling: auto;
}

img {
  border: none;
}

a {
  cursor: pointer;
  color: #323650;
  transition: color 0.2s;
  text-decoration: none;
}

@media (min-width: 1024px) {
  body:not(.mobile) a:hover {
    color: #6852D9;
    text-decoration: none;
  }
}

p {
  margin-bottom: 20px;
}

p small {
  font-size: 0.8em;
}

h1, h2, h3, h4, h5, h6 {
  font: 400 18px/1.2 "Roboto", sans-serif;
  color: #4D4D4D;
  margin-bottom: 15px;
}

h1 {
  font-size: 24px;
}

h3 {
  font-size: 17px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 14px;
}

input::-ms-clear {
  display: none;
}

input[type='submit'], input[type='button'], button {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

ul, ol {
  padding-left: 30px;
  margin-bottom: 15px;
}

ul li {
  list-style: disc outside;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

.video-block {
  margin-bottom: 18px;
}

.video-block-head {
  margin: 0 -6px 9px;
  padding: 6px;
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .video-block-head {
    margin: 0 -2px 11px;
  }
}

.video-block-head-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.video-block-head-title h1 {
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 0;
  max-width: 100%;
  margin: 0 11px 0 0;
  padding: 6px 0 7px 31px;
  font-size: 18px;
  line-height: 21px;
  position: relative;
  word-wrap: break-word;
}

.video-block-head-title h1:before {
  background: url(../icons/16.svg) 50% 50% no-repeat;
  content: "";
  display: inline-block;
  width: 22px;
  height: 21px;
  position: absolute;
  top: 6px;
  left: 0;
}

.video-block-head-title-btn {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  width: 20px;
  height: 34px;
  cursor: pointer;
  background: url(../icons/12_v.svg) no-repeat 50% 50%;
  position: relative;
  transition: background-color 0.2s;
}

.video-block-head-title-btn:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: url("../icons/underline.svg") center/100% 100%;
  opacity: 0;
  transition: opacity 0.2s, box-shadow 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .video-block-head-title-btn:hover:before {
    opacity: 1;
    box-shadow: 0 2px 8px #A498E1;
  }
}

.video-block-head-success {
  padding: 10px;
  font-size: 22px;
  color: #4D4D4D;
  text-align: center;
  display: none;
}

.video-block-head-msg {
  width: 100%;
  max-width: 1185px;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.2s, opacity 0.2s, padding 0.2s;
}

.video-block-head-msg.opened {
  max-height: 500px;
  opacity: 1;
  padding: 18px 0 9px;
  overflow: visible;
}

.video-block-head-msg-cont {
  margin-bottom: 9px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

@media (max-width: 767px) {
  .video-block-head-msg-cont {
    flex-direction: column;
  }
}

.video-block-head-msg-cont-fw {
  flex-grow: 0;
  flex-shrink: 0;
  width: 202px;
  margin-right: 18px;
}

@media (max-width: 767px) {
  .video-block-head-msg-cont-fw {
    width: 100%;
    margin: 0 0 18px;
  }
}

.video-block-head-msg-cont-mw {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
}

.video-block-head-msg__p {
  margin-top: 4px;
}

.video-block-head-msg__t {
  width: 100%;
}

.video-block-head-msg__t textarea {
  display: block;
  width: 100%;
  height: 164px;
  padding: 17px 20px;
  background-color: #fff;
  border: 1px solid #CCCBD4;
  border-radius: 10px;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  font: 12px "Roboto", sans-serif;
  color: #4D4D4D;
  text-transform: uppercase;
}

.video-block-head-msg__t textarea::-webkit-input-placeholder {
  color: #4D4D4D;
}

.video-block-head-msg__t textarea::-moz-placeholder {
  color: #4D4D4D;
}

.video-block-head-msg__t textarea:-moz-placeholder {
  color: #4D4D4D;
}

.video-block-head-msg__t textarea:-ms-input-placeholder {
  color: #4D4D4D;
}

.video-block-head-msg__t textarea:focus {
  border-color: #807f88;
}

.video-block-head-msg__t textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.video-block-head-msg__t textarea:focus::-moz-placeholder {
  color: transparent;
}

.video-block-head-msg__t textarea:focus:-moz-placeholder {
  color: transparent;
}

.video-block-head-msg__t textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.video-block-head-msg-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
}

.video-block-head-msg-btn button {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 100px;
  height: 40px;
  padding: 0 20px;
  border: none;
  background: #fff;
  border-radius: 10px;
  font-size: 14px;
  color: #4D4D4D;
  box-shadow: 0 2px 6px #E8E7EF;
  transition: background 0.2s, color 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .video-block-head-msg-btn button:hover {
    color: #fff;
    background: #6852D9;
  }
}

.video-block-body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
}

@media (max-width: 1279px) {
  .video-block-body {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.video-block-body-main {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  max-width: 1274px;
  min-width: 0;
  overflow: hidden;
}

@media (max-width: 1023px) {
  .video-block-body-main .thumbs-more {
    display: none;
  }
}

.video-block-body-side {
  flex-grow: 0;
  flex-shrink: 0;
  width: 300px;
  margin-left: 10px;
  padding-top: 71px;
}

@media (max-width: 1279px) {
  .video-block-body-side {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.video-block-body-side .spec-img-list {
  margin: 0;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 1279px) {
  .video-block-body-side .spec-img-list {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
}

.video-block-body-side .spec-img a {
  margin: 0;
}

.video-block-body-side .spec-img a:not(:first-child) {
  margin-top: 14px;
}

@media (max-width: 1279px) {
  .video-block-body-side .spec-img a {
    margin: 10px 50px;
  }
}

@media (max-width: 1023px) {
  .video-block-body-side .spec-img a {
    margin: 10px 25px;
  }
}

@media (max-width: 767px) {
  .video-block-body-side .spec-img a {
    margin: 10px;
  }
}

@media (max-width: 479px) {
  .video-block-body-side .spec-img a {
    margin: 10px 0;
  }
}

.video-block-tags {
  max-height: 64px;
  margin-right: -22px;
  margin-bottom: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  transition: max-height 0.2s;
}

.video-block-tags span {
  flex-grow: 0;
  flex-shrink: 0;
  order: 10;
  display: block;
  margin-right: 21px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 22px;
  position: relative;
  transition: opacity 0.2s;
}

.video-block-tags span.m-r {
  margin-right: 32px;
}

.video-block-tags span.t-i {
  padding-left: 36px;
}

.video-block-tags span.t-i i {
  display: block;
  width: 22px;
  height: 22px;
  background: #22293c linear-gradient(to left, #0788f7 0%, #19afe8 100%);
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.video-block-tags span.t-i i:before {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.video-block-tags span.t-i i.t-i_s:before {
  background: url(../icons/9_d.svg) no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 11px;
  height: 11px;
}

.video-block-tags span.t-i i.t-i_r:before {
  background: url(../icons/10.svg) no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 17px;
  height: 16px;
  width: 12px;
  height: 12px;
  background-size: contain;
}

.video-block-tags span.t-i i.t-i_n:before {
  background: url(../icons/13.svg) no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 19px;
  height: 14px;
  width: 12px;
  height: 9px;
  background-size: contain;
}

.video-block-tags span.t-i i.t-i_i:before {
  background: url(../icons/14.svg) no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 15px;
  height: 20px;
  width: 10px;
  height: 12px;
  background-size: contain;
}

.video-block-tags span.hdn {
  order: 30;
  opacity: 0;
  visibility: hidden;
}

.video-block-tags span a {
  color: #ffffff;
}

@media (min-width: 1024px) {
  body:not(.mobile) .video-block-tags span a:hover {
    color: #8394af;
  }
}

.video-block-tags .sh-h {
  flex-grow: 0;
  flex-shrink: 0;
  order: 20;
  display: block;
  padding: 0 6px;
  font-size: 16px;
  color: #323650;
}

.video-block-tags.opened {
  max-height: 600px;
}

.video-block-tags.opened span.hdn {
  order: 10;
  opacity: 1;
  visibility: visible;
}

.video-block-added {
  margin-bottom: 17px;
  padding-left: 36px;
  color: #ffffff;
  position: relative;
}

.video-block-added > u {
  display: block;
  width: 22px;
  height: 22px;
  background: #22293c linear-gradient(to left, #0788f7 0%, #19afe8 100%);
  border-radius: 5px;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
}

.video-block-added > u:before {
  background: url(../icons/11.svg) no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  width: 13px;
  height: 13px;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.video-block-added span {
  color: #323650;
}

.video-block-video {
  min-height: 360px;
  position: relative;
  padding: 56.25% 0 0;
  box-sizing: border-box;
  overflow: hidden;
}

.video-block-video > img, .video-block-video iframe, .video-block-video video {
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
}

.video-block-video .video-js {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0;
  right: 0;
}

.video-block-video .video-js .vgs-big-play-button {
  height: 3em;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  line-height: 3em;
}

.video-block-voting {
  padding: 12px 7px 26px;
  position: relative;
}

.video-block-voting-msg {
  max-width: 100%;
  min-width: 300px;
  background-color: #fff;
  padding: 8px 14px;
  border: 1px solid #CCCBD4;
  visibility: hidden;
  opacity: 0;
  margin: 0 0 5px;
  overflow: hidden;
  color: #4D4D4D;
  position: absolute;
  bottom: 100%;
  left: 0;
  transform: translateY(100%);
  box-sizing: border-box;
  text-align: center;
  transition: .2s all 2s;
}

.video-block-voting-msg.a {
  opacity: 1;
  visibility: visible;
  transition: all .4s;
  transform: translateY(0);
}

.video-block-thumbs {
  width: 100%;
  margin-bottom: 6px;
  overflow: hidden;
}

@media (max-width: 1023px) {
  .video-block-thumbs .g {
    flex-grow: 1;
    flex-shrink: 0;
    width: auto;
    margin: 0;
    flex-wrap: nowrap;
  }
}

@media (max-width: 1023px) {
  .video-block-thumbs .g-w {
    padding-bottom: 30px;
    margin-bottom: -30px;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    -webkit-overflow-scrolling: touch;
  }
}

.video-block-thumbs .g__i {
  width: calc(16.666% + -5px);
  margin-right: 5px;
  margin-bottom: 7px;
}

@media (max-width: 1023px) {
  .video-block-thumbs .g__i {
    width: 190px;
    margin: 0 3px 0 0;
  }
}

@media (max-width: 767px) {
  .video-block-thumbs .g__i {
    width: 180px;
  }
}

@media (max-width: 479px) {
  .video-block-thumbs .g__i {
    width: 140px;
  }
}

.video-block-thumbs .g__i:nth-child(n + 7) {
  display: none;
}

@media (max-width: 1023px) {
  .video-block-thumbs .g__i:nth-child(n + 7) {
    display: block;
  }
}

.video-block-thumbs .g__i p, .video-block-thumbs .g__i h3 {
  max-height: 36px;
  margin-top: 7px;
  font-size: 14px;
  line-height: 18px;
}

.video-block-thumbs .g__i_can-play a:hover .g__i-img:after {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  content: "";
  box-sizing: border-box;
  border-left: 34.64px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  width: 40px;
  height: 40px;
  opacity: .6;
  pointer-events: none;
}

.video-block-thumbs-wr.show-all .g__i:nth-child(n + 7) {
  display: block;
}

.checkbox_main {
  position: absolute;
  opacity: 0;
  margin: 0;
}

.checkbox_main + label {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  color: #4D4D4D;
  line-height: 22px;
}

.checkbox_main + label:before, .checkbox_main + label:after {
  content: '';
  position: absolute;
  display: block;
}

.checkbox_main + label:before {
  width: 22px;
  height: 22px;
  background-color: #fff;
  border: 1px solid #CCCBD4;
  box-sizing: border-box;
  top: 0;
  left: 0;
  transition: border-color 0.2s;
}

.checkbox_main + label:after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  top: 6px;
  left: 6px;
  transition: background-color 0.2s;
}

.checkbox_main:checked + label:before {
  border-color: #807f88;
}

.checkbox_main:checked + label:after {
  background-color: #6852D9;
}

.vote {
  height: 24px;
  padding-left: 33px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
}

.vote:before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background: url("../icons/vote.svg") no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}

.vote-like, .vote-dislike {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 28px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4D4D4D;
  text-transform: uppercase;
  transition: color 0.2s;
}

.vote-like {
  order: 1;
}

@media (min-width: 1024px) {
  body:not(.mobile) .vote-like:hover {
    color: #6852D9;
  }
}

.vote-dislike {
  order: 30;
}

@media (min-width: 1024px) {
  body:not(.mobile) .vote-dislike:hover {
    color: #FF4652;
  }
}

.vote-r {
  order: 20;
  padding: 0 16px;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #533EBF;
  position: relative;
  top: 1px;
}

.vote-r small {
  font-size: 14px;
}

.video-block-video > video.video-js {
  visibility: hidden;
}

.video-block-video .video-js {
  width: 100%;
  height: 100%;
  position: absolute !important;
  left: 0;
  top: 0;
}

.video-block-video .video-js .vjs-fullscreen-control.vjs-control.vjs-button {
  -webkit-order: 20;
  -ms-flex-order: 20;
  order: 20;
}

.video-block-video .video-js .vjs-big-play-button {
  height: 3em;
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  line-height: 3em;
}

.video-block-video .video-js .vjs-big-play-button .vjs-icon-placeholder {
  font-size: 2em;
}

.video-block-video .video-js .vjs-poster {
  background-size: cover;
}

.lang-menu {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #ffffff;
  text-transform: uppercase;
  z-index: 20;
  flex-shrink: 0;
  flex-grow: 0;
  /*&:before {
        position: absolute;
        right: 0;
        max-width: 100%;
        width: 560px;
        content: '';
        top: 50%;
        bottom: -1px;
        display: none;
    }*/
  /* &:hover &__list {
         max-height: 500px;
         padding: 8px 10px;
     }
     &:hover {
         &:before {
             display: block;
         }
     }*/
  /*&:hover {
        &:before {
            display: block;
        }
    }*/
}

@media (max-width: 767px) {
  .lang-menu {
    position: static;
    margin-left: auto;
  }
}

.lang-menu__label {
  display: block;
  position: relative;
  padding-right: 16px;
  cursor: pointer;
  white-space: nowrap;
  min-width: 22px;
  /* @include sm {

         }*/
}

.lang-menu__label .flag {
  display: block;
  float: left;
  margin-right: 10px;
}

@media (max-width: 1023px) {
  .lang-menu__label .flag {
    margin-right: 0;
    top: 0;
  }
}

.lang-menu__label:before {
  background: url("../icons/1.svg") no-repeat 50% 50%;
  content: "";
  display: block;
  width: 8px;
  height: 4px;
  position: absolute;
  top: 5px;
  right: 0;
}

@media (max-width: 1023px) {
  .lang-menu__label:before {
    top: 6px;
  }
}

@media (max-width: 1023px) {
  .lang-menu__label {
    font-size: 0;
    line-height: 0;
    text-indent: -99in;
    padding-right: 31px;
  }
}

@media (min-width: 1024px) {
  body:not(.mobile) .lang-menu__label:hover {
    color: #ffffff;
  }
}

.lang-menu__list {
  position: absolute;
  top: 100%;
  margin-left: -10px;
  background: #6852D9;
  border-radius: 0 0 10px 10px;
  width: 530px;
  box-sizing: border-box;
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s, padding 0.2s;
}

.lang-menu__list ul {
  column-width: 145px;
  column-gap: 35px;
  font-size: 0;
  line-height: 0;
}

.lang-menu__list li {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  padding: 7px 0 8px;
}

.lang-menu__list li .flag {
  display: block;
  width: 22px;
  float: left;
  margin-right: 10px;
  border-radius: 2px;
}

.lang-menu__list li a {
  color: #fff;
  transition: color 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .lang-menu__list li a:hover {
    color: #FF4652;
  }
}

.lang-menu__list li.active a {
  color: #FF4652;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .lang-menu__list {
    width: auto;
    margin: 0;
    top: 60px;
    right: 0;
    left: 0;
  }
}

.lang-menu #lang-menu__check {
  position: absolute;
  left: -99in;
  top: -99in;
}

#lang-menu__check:checked ~ .lang-menu__label {
  color: #ffffff;
}

#lang-menu__check:checked ~ .lang-menu__list {
  max-height: 884px;
  padding: 5px 10px 9px;
}

@media (max-width: 767px) {
  #lang-menu__check:checked ~ .lang-menu__list {
    max-height: 442px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  width: 100%;
  max-width: 100%;
  margin-bottom: 25px;
  position: relative;
  z-index: 200;
}

@media (max-width: 767px) {
  .header {
    margin-bottom: 9px;
    background-color: #ffffff;
  }
}

.header:before {
  content: '';
  height: 60px;
  background: #6852D9;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: none;
}

@media (max-width: 767px) {
  .header:before {
    display: block;
  }
}

header {
  width: 100%;
  max-width: 1604px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  position: relative;
  z-index: 200;
  padding: 0 10px;
}

@media (max-width: 767px) {
  header {
    flex-wrap: wrap;
    padding: 0 10px 9px;
    background-color: transparent;
  }
}

header > * {
  min-width: 0;
  max-width: 100%;
}

.logo {
  display: block;
  margin-right: 22px;
}

.logo img {
  display: block;
}

.logo,
nav,
.nbttn {
  flex-shrink: 0;
  flex-grow: 0;
}

nav {
  order: 30;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  margin: 10px 20px 0 0;
  padding: 0;
  box-sizing: border-box;
}

nav a {
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 0;
  color: #323650;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  margin-left: 5px;
  padding: 0 15px 10px;
  text-transform: uppercase;
  transition: color 0.2s;
}

nav a:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: url("../icons/underline.svg") center/100% 100%;
  opacity: 0;
  transition: opacity 0.2s, box-shadow 0.2s;
}

@media (max-width: 1023px) {
  nav a:before {
    display: none;
  }
}

nav a.active {
  color: #6852D9;
}

nav a.active:before {
  opacity: 1;
  box-shadow: 0 2px 8px #A498E1;
}

@media (max-width: 1023px) {
  nav a.active {
    color: #FF4652;
  }
}

@media (min-width: 1024px) {
  body:not(.mobile) nav a:hover {
    color: #6852D9;
  }
  body:not(.mobile) nav a:hover:before {
    opacity: 1;
    box-shadow: 0 2px 8px #A498E1;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  nav a {
    font-size: 13px;
    line-height: 15px;
    padding: 0 10px 8px;
  }
}

@media (max-width: 1023px) {
  nav a {
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
}

nav a.n-upl {
  width: 24px;
  margin-left: 15px;
  padding: 0 3px 10px;
  text-indent: -99in;
}

@media (max-width: 1023px) {
  nav a.n-upl {
    width: auto;
    margin: 0;
    padding: 10px 12px;
    text-indent: 0;
  }
}

nav a.n-upl:after {
  content: '';
  width: 24px;
  height: 19px;
  background: url("../icons/2.svg") center no-repeat;
  position: absolute;
  top: -1px;
  left: 3px;
}

@media (max-width: 1023px) {
  nav a.n-upl:after {
    display: none;
  }
}

@media (max-width: 1023px) {
  nav {
    min-height: 0;
    max-height: 0;
    padding: 0;
    margin: 0;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 60px;
    background-color: #6852D9;
    width: 100%;
    align-items: flex-start;
    overflow: hidden;
    transition: padding 0.2s, max-height 0.2s;
    z-index: 20;
  }
  nav a {
    align-self: stretch;
    padding: 10px 12px;
    margin: 0 0 10px;
  }
  #nbtn:checked ~ nav {
    max-height: 500px;
    padding: 22px 0 55px;
  }
}

#nbtn {
  display: none;
}

.nbttn {
  background: url(../icons/3_1.svg) no-repeat 50% 50%;
  width: 31px;
  height: 31px;
  display: none;
  margin-left: 34px;
  order: 60;
}

@media (max-width: 1023px) {
  .nbttn {
    display: block;
  }
}

@media (max-width: 767px) {
  .nbttn {
    margin: 0 0 0 auto;
    background-image: url(../icons/3.svg);
  }
}

.search {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  order: 20;
  width: 100%;
  margin-left: auto;
  position: relative;
}

.search > * {
  min-width: 0;
  display: block;
  box-sizing: border-box;
  margin: 0;
}

.search > input[type="text"] {
  appearance: none;
  flex-shrink: 1;
  flex-grow: 1;
  padding: 0 54px 0 18px;
  color: #4D4D4D;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  height: 54px;
  background-color: #fff;
  border: 1px solid #CCCBD4;
  border-radius: 10px;
  text-transform: uppercase;
  transition: border-color 0.2s;
}

.search > input[type="text"]::-webkit-input-placeholder {
  color: #4D4D4D;
}

.search > input[type="text"]::-moz-placeholder {
  color: #4D4D4D;
}

.search > input[type="text"]:-moz-placeholder {
  color: #4D4D4D;
}

.search > input[type="text"]:-ms-input-placeholder {
  color: #4D4D4D;
}

.search > input[type="text"]:focus {
  border-color: #807F88;
  color: #4D4D4D;
}

.search > input[type="text"]:focus::-webkit-input-placeholder {
  color: transparent;
}

.search > input[type="text"]:focus::-moz-placeholder {
  color: transparent;
}

.search > input[type="text"]:focus:-moz-placeholder {
  color: transparent;
}

.search > input[type="text"]:focus:-ms-input-placeholder {
  color: transparent;
}

@media (max-width: 767px) {
  .search > input[type="text"] {
    padding: 0 77px 0 23px;
  }
}

.search > [type="submit"] {
  appearance: none;
  /*flex-shrink: 0;
        flex-grow: 0;*/
  border: none;
  width: 54px;
  height: 54px;
  padding: 0;
  border-radius: 10px;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
}

.search > [type="submit"]:before {
  background: url(../icons/4.svg) no-repeat 50% 50%;
  content: "";
  display: block;
  width: 22px;
  height: 23px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  transition: background 0.2s;
}

@media (max-width: 767px) {
  .search {
    max-width: 100%;
    width: 100%;
    order: 90;
    margin: 9px 0 0;
  }
}

.user-menu {
  flex-grow: 0;
  flex-shrink: 0;
  order: 50;
  margin: 0 20px 0 17px;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .user-menu {
    margin: 0 15px 0 12px;
  }
}

@media (max-width: 1023px) {
  .user-menu {
    margin-left: 10px;
  }
}

.user-menu-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.user-menu-item {
  flex-shrink: 0;
  flex-grow: 0;
}

.user-menu-item:not(:first-child) {
  margin-left: 5px;
}

.user-menu-item-link {
  display: block;
}

.logo-lang {
  flex-grow: 0;
  flex-shrink: 0;
  order: 10;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background: #6852D9;
  border-radius: 0 10px 10px 0;
  margin: 0 15px 0 -10px;
  padding: 16px 18px 16px 10px;
  position: relative;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .logo-lang {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .logo-lang {
    position: static;
    margin: 0 15px 0 0;
    padding: 16px 0;
    background: transparent;
    border-radius: 0;
  }
}

@media (max-width: 335px) {
  .g-w {
    width: 100%;
    overflow: hidden;
  }
}

.g {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  margin-right: -3px;
}

@media (max-width: 335px) {
  .g {
    margin-right: -3px;
  }
}

.g__i {
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 0;
  box-sizing: border-box;
  position: relative;
  width: calc(20% + -3px);
  margin-right: 3px;
  margin-bottom: 10px;
  z-index: 10;
}

@media (max-width: 1607px) {
  .g__i {
    width: calc(20% + -3px);
  }
}

@media (max-width: 1288px) {
  .g__i {
    width: calc(25% + -3px);
  }
}

@media (max-width: 969px) {
  .g__i {
    width: calc(33.33333% + -3px);
  }
}

@media (max-width: 650px) {
  .g__i {
    width: calc(50% + -3px);
  }
}

@media (max-width: 331px) {
  .g__i {
    width: calc(100% + -3px);
  }
}

.g__i-img {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.32911%;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s;
}

.g__i-img .video-loader {
  display: none;
}

.g__i-img video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -99;
  opacity: 0;
  transition: opacity 0.2s;
}

.g__i-img video.loadcl {
  opacity: 1;
}

.g__i-img_slider-end:hover:after,
.mobile .g__i-img_slider-end:after {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  content: "";
  box-sizing: border-box;
  border-left: 34.64px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  width: 40px;
  height: 40px;
  opacity: .6;
  pointer-events: none;
}

.g__i-img_video-hover img {
  z-index: 5;
}

.g__i-img_video-hover video {
  opacity: 1;
  z-index: 10;
}

.g__i-img_video-hover .video-loader {
  display: block;
  z-index: 8;
}

.g__i-img_video-hover.video_loaded:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: '';
  transition: all 0.2s;
  background-color: #000;
  z-index: 7;
}

.g__i-img.video_loaded .video-loader {
  display: none;
}

.g__i img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.g__i p {
  max-width: 100%;
  max-height: 36px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 5px;
  margin: 7px 0 0;
  transition: all 0.2s;
  color: #4D4D4D;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.g__i-title {
  margin: 9px 0 0;
  padding: 0 5px;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #4D4D4D;
  position: relative;
  transition: all 0.2s;
}

.g__i-title span {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.g__i-time, .g__i-info {
  font-size: 12px;
  line-height: 22px;
  color: #ffffff;
  position: absolute;
  top: -24px;
  right: 3px;
  margin-top: 56.32911%;
  padding: 0 8px;
  height: 22px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 15;
}

@media (max-width: 600px) {
  .g__i-time, .g__i-info {
    height: 20px;
    padding: 0 4px;
    line-height: 20px;
  }
}

.g__i-info {
  padding: 0 6px;
}

.g__i-report {
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7) url("../icons/12.svg") center no-repeat;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 15;
  transition: background 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .g__i-report:hover {
    background: #6852D9 url("../icons/12.svg") center no-repeat;
  }
}

.g__i:hover {
  z-index: 20;
}

.g__i:hover p,
.g__i:hover .g__i-title {
  color: #6852D9;
}

@media (max-width: 335px) {
  .g__i {
    width: 100%;
    margin: 0 0 17px;
  }
}

.g__i_nl:hover p,
.g__i_nl:hover h3 {
  color: #6852D9;
}

.video-loader, .video-loader:before, .video-loader:after {
  background: #ffffff;
  animation: loader 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.video-loader {
  color: #ffffff;
  text-indent: -9999em;
  font-size: 11px;
  animation-delay: -0.16s;
  position: absolute;
  margin: 0;
  right: 15px;
  top: -3px;
  transform: scale(0.3);
}

.video-loader:before, .video-loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.video-loader:before {
  left: -1.5em;
  animation-delay: -0.32s;
}

.video-loader:after {
  left: 1.5em;
}

@keyframes loader {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.thumbs {
  width: 100%;
  position: relative;
  margin-bottom: 33px;
}

.thumbs .g-w {
  margin-bottom: 7px;
}

.thumbs-more {
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
}

.thumbs-more .view-more {
  display: block;
  text-transform: uppercase;
  flex-grow: 0;
  flex-shrink: 0;
}

.thumbs_cat {
  margin-bottom: 37px;
}

.view-more {
  display: inline-block;
  padding: 0 15px 10px 37px;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #323650;
  transition: color 0.2s;
}

.view-more:before {
  background: url(../icons/8.svg) no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 14px;
  height: 15px;
  position: absolute;
  top: 1px;
  left: 15px;
  transition: background 0.2s;
}

.view-more:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: url("../icons/underline.svg") center/100% 100%;
  opacity: 0;
  transition: opacity 0.2s, box-shadow 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .view-more:hover {
    color: #6852D9;
  }
  body:not(.mobile) .view-more:hover:before {
    background: url(../icons/8_h.svg) no-repeat 50% 50%;
  }
  body:not(.mobile) .view-more:hover:after {
    opacity: 1;
    box-shadow: 0 2px 8px #A498E1;
  }
}

.footer-wrapper {
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-top: auto;
}

.footer {
  width: 100%;
  max-width: 1604px;
  margin: 0 auto;
  box-sizing: border-box;
  color: #fff;
  padding: 0 6px 5px;
  position: relative;
  background: #6852D9;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.footer > * {
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 0;
  display: block;
}

@media (max-width: 767px) {
  .footer {
    padding: 0 2px 5px;
    font-size: 16px;
  }
}

.footer > *:last-child {
  margin-bottom: 0;
}

.footer .logo {
  display: block;
  margin: 0 auto 3px;
}

@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
}

.foot-lang {
  font-size: 12px;
  line-height: 14px;
  background-color: #ffffff;
  margin: 0 -6px 11px;
  padding: 17px 6px 10px;
}

.foot-lang p {
  margin: 0;
}

.foot-lang a {
  display: inline-block;
  margin: 0 8.5px 7px;
  color: #323650;
}

@media (min-width: 1024px) {
  body:not(.mobile) .foot-lang a:hover {
    color: #6852D9;
  }
}

.foot-lang a.active {
  color: #6852D9;
}

@media (max-width: 767px) {
  .foot-lang {
    margin: 0 -2px 11px;
    padding: 17px 2px 10px;
  }
}

.foot-nav {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.foot-nav a {
  display: inline-block;
  margin: 0 19.5px 8px;
  color: #ffffff;
}

@media (min-width: 1024px) {
  body:not(.mobile) .foot-nav a:hover {
    color: #FF4652;
  }
}

.copyright {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 13px;
}

.h {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px 6px;
  margin: 0 -6px 10px;
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .h {
    flex-direction: column;
    align-items: stretch;
    margin: 0 -2px 8px;
    padding: 12px 6px 11px;
  }
}

.h__t {
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 0;
  display: block;
  margin-right: auto;
  padding: 2px 20px 2px 0;
  max-width: 100%;
  position: relative;
  word-wrap: break-word;
  box-sizing: border-box;
  overflow: hidden;
  align-self: stretch;
}

@media (max-width: 767px) {
  .h__t {
    width: 100%;
    padding-right: 0;
  }
}

.h__t * {
  display: inline;
}

.h__t-h {
  margin-right: 8px;
  position: relative;
  padding-left: 31px;
}

.h__t-h,
.h__t-h * {
  color: #4D4D4D;
  font-size: 18px;
  font-weight: 400;
}

.h__t-h:before {
  background: url(../icons/16.svg) no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 22px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .h__t-h {
    display: block;
    margin-bottom: 2px;
  }
}

.h__f {
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 0;
  padding: 0 8px;
}

@media (max-width: 767px) {
  .h__f {
    padding: 23px 0 0;
  }
}

.h__f .filter-dropdown {
  right: 0;
  left: auto;
}

.filter {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.filter-list {
  flex-shrink: 0;
  display: flex;
}

@media (max-width: 767px) {
  .filter-list {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .filter-list {
    justify-content: space-between;
  }
}

.filter-list a {
  flex-shrink: 0;
  display: block;
  margin: 0 6px 0 5px;
  padding: 0 12px 10px;
  font-weight: 500;
  line-height: 16px;
  color: #323650;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.2s;
}

.filter-list a:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: url("../icons/underline.svg") center/100% 100%;
  opacity: 0;
  transition: opacity 0.2s, box-shadow 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .filter-list a:hover {
    color: #6852D9;
  }
  body:not(.mobile) .filter-list a:hover:before {
    opacity: 1;
    box-shadow: 0 2px 8px #A498E1;
  }
}

.filter-list a.active {
  color: #6852D9;
}

.filter-list a.active:before {
  opacity: 1;
  box-shadow: 0 2px 8px #A498E1;
}

@media (max-width: 767px) {
  .filter-list a {
    margin: 0 2px;
  }
}

.pager {
  margin-bottom: 22px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.pager-list {
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pager-list-item {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 10.5px 2px;
}

@media (max-width: 479px) {
  .pager-list-item {
    margin: 0 2px 2px;
  }
}

.pager-list-item-link {
  display: block;
  min-width: 35px;
  height: 41px;
  padding: 10px 12px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #323650;
  position: relative;
  text-align: center;
  transition: color 0.2s;
}

.pager-list-item-link:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: url("../icons/underline.svg") center/100% 100%;
  opacity: 0;
  transition: opacity 0.2s, box-shadow 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .pager-list-item-link:hover {
    color: #6852D9;
  }
  body:not(.mobile) .pager-list-item-link:hover:before {
    opacity: 1;
    box-shadow: 0 2px 8px #A498E1;
  }
}

.pager-list-item-link.cur-pages {
  min-width: 176px;
  height: 50px;
  padding: 0 11px;
  background: #6852D9;
  border-radius: 10px;
  line-height: 50px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .pager-list-item-link.cur-pages {
    min-width: 0;
  }
}

.pager-list-item-link.cur-pages:before {
  display: none;
}

.pager-list-item-link.cur-pages i {
  flex-shrink: 0;
  display: block;
  flex-basis: calc(33.333% - 20px);
  margin: 0 10px;
  font-style: normal;
  text-align: center;
}

@media (max-width: 767px) {
  .pager-list-item-link.cur-pages i {
    margin: 0 5px;
  }
}

.pager-list-item-link.cur-pages i:first-child {
  text-align: left;
}

.pager-list-item-link.cur-pages i:last-child {
  text-align: right;
}

@media (min-width: 1024px) {
  body:not(.mobile) .pager-list-item-link.cur-pages:hover {
    color: #ffffff;
  }
}

.pager-list-item_prev .pager-list-item-link, .pager-list-item_next .pager-list-item-link, .pager-list-item_first .pager-list-item-link, .pager-list-item_last .pager-list-item-link {
  text-indent: -99in;
  overflow: hidden;
  position: relative;
}

.pager-list-item_prev {
  margin-left: 0;
}

.pager-list-item_prev .pager-list-item-link {
  width: 37px;
}

.pager-list-item_prev .pager-list-item-link:after {
  content: "";
  background: url(../icons/6.svg) no-repeat 50% 50%;
  display: block;
  width: 13px;
  height: 13px;
  position: absolute;
  top: 15px;
  left: 12px;
  transition: background 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .pager-list-item_prev .pager-list-item-link:hover:after {
    background: url(../icons/6_h.svg) no-repeat 50% 50%;
  }
}

.pager-list-item_next {
  margin-right: 0;
}

.pager-list-item_next .pager-list-item-link:after {
  background: url(../icons/7.svg) no-repeat 50% 50%;
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  position: absolute;
  top: 15px;
  right: 12px;
  transition: background 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .pager-list-item_next .pager-list-item-link:hover:after {
    background: url(../icons/7_h.svg) no-repeat 50% 50%;
  }
}

.pager-list-item_first {
  margin: 0 20.5px 0 0;
}

.pager-list-item_first .pager-list-item-link {
  width: 44px;
}

.pager-list-item_first .pager-list-item-link:after {
  background: url(../icons/9.svg) no-repeat 50% 50%;
  content: "";
  display: block;
  width: 20px;
  height: 12px;
  position: absolute;
  top: 14px;
  left: 12px;
  transition: background 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .pager-list-item_first .pager-list-item-link:hover:after {
    background: url(../icons/9_h.svg) no-repeat 50% 50%;
  }
}

.pager-list-item_last {
  margin: 0 0 0 20.5px;
}

.pager-list-item_last .pager-list-item-link {
  width: 44px;
}

.pager-list-item_last .pager-list-item-link:after {
  background: url(../icons/10.svg) no-repeat 50% 50%;
  content: "";
  display: block;
  width: 20px;
  height: 12px;
  position: absolute;
  top: 14px;
  right: 12px;
  transition: background 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .pager-list-item_last .pager-list-item-link:hover:after {
    background: url(../icons/10_h.svg) no-repeat 50% 50%;
  }
}

.pager-list-item.range {
  margin: 0 22.5px 2px;
}

@media (max-width: 479px) {
  .pager-list-item.range {
    margin: 0 7.5px 2px;
  }
}

.pager-list-item.active .pager-list-item-link {
  color: #6852D9;
}

.pager-list-item.active .pager-list-item-link:before {
  opacity: 1;
  box-shadow: 0 2px 8px #A498E1;
}

.txtcl {
  margin-bottom: 34px;
  text-align: center;
}

.txtcl > *:last-child {
  margin-bottom: 0;
}

.tags {
  margin-bottom: 62px;
}

.tags-cat {
  width: 100%;
  overflow: hidden;
  margin-bottom: 6px;
}

.tags-cat-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-right: -4px;
  padding-top: 7px;
}

.tags-cat-list__el {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  margin: 0 4px 6px 0;
  padding: 0 8px 10px;
  font-size: 14px;
  line-height: 16px;
  color: #323650;
  position: relative;
  transition: color 0.2s;
}

.tags-cat-list__el:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: url("../icons/underline.svg") center/100% 100%;
  opacity: 0;
  transition: opacity 0.2s, box-shadow 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .tags-cat-list__el:hover {
    color: #6852D9;
  }
  body:not(.mobile) .tags-cat-list__el:hover:before {
    opacity: 1;
    box-shadow: 0 2px 8px #A498E1;
  }
}

.tags-cat-list__el.active {
  color: #6852D9;
}

.tags-cat-list__el.active:before {
  opacity: 1;
  box-shadow: 0 2px 8px #A498E1;
}

.tags-l {
  width: 100%;
  overflow: hidden;
  margin-bottom: 12px;
}

.tags-l .h {
  margin-bottom: 17px;
}

.tags-l-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-right: -4px;
}

.tags-l-list__el {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  margin: 3px 4px 0 0;
  padding: 3px 8px 10px;
  font-size: 14px;
  line-height: 16px;
  color: #323650;
  position: relative;
  transition: color 0.2s;
}

.tags-l-list__el:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: url("../icons/underline.svg") center/100% 100%;
  opacity: 0;
  transition: opacity 0.2s, box-shadow 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .tags-l-list__el:hover {
    color: #6852D9;
  }
  body:not(.mobile) .tags-l-list__el:hover:before {
    opacity: 1;
    box-shadow: 0 2px 8px #A498E1;
  }
}

.tags-l-list__el.active {
  color: #6852D9;
}

.tags-l-list__el.active:before {
  opacity: 1;
  box-shadow: 0 2px 8px #A498E1;
}

.tags-l-list__el-ic {
  width: 22px;
  height: 22px;
  padding: 0;
  background: url("../icons/17.svg") 50% 50% no-repeat;
}

.tags-l-list__el-ic:before {
  display: none;
}

.tags-l-list__el-ic_v1 {
  background-image: url(../icons/18.svg);
}

.tags-l-list__el-ic_v2 {
  background-image: url(../icons/19.svg);
}

.tags-l-list > span {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  font-size: 14px;
  line-height: 16px;
  margin: 3px 0 0 12px;
  padding: 3px 0 10px;
}

.tags-l-list > span span {
  color: #6852D9;
}

.tags-l-w {
  margin-bottom: 7px;
}

.categories {
  margin-bottom: 37px;
}

.categories-search {
  margin-bottom: 5px;
  position: relative;
  z-index: 10;
}

.categories-search-form {
  width: 100%;
  max-width: 450px;
}

.categories-search-form input {
  display: block;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background: #fff;
  border: 1px solid #CCCBD4;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  color: #4D4D4D;
  transition: border-color 0.2s;
}

.categories-search-form input::-webkit-input-placeholder {
  color: #4D4D4D;
}

.categories-search-form input::-moz-placeholder {
  color: #4D4D4D;
}

.categories-search-form input:-moz-placeholder {
  color: #4D4D4D;
}

.categories-search-form input:-ms-input-placeholder {
  color: #4D4D4D;
}

.categories-search-form input:focus {
  border-color: #807F88;
}

.categories-search-form input:focus::-webkit-input-placeholder {
  color: transparent;
}

.categories-search-form input:focus::-moz-placeholder {
  color: transparent;
}

.categories-search-form input:focus:-moz-placeholder {
  color: transparent;
}

.categories-search-form input:focus:-ms-input-placeholder {
  color: transparent;
}

.categories-list {
  column-count: 5;
  column-gap: 37px;
}

@media (max-width: 1439px) {
  .categories-list {
    column-count: 4;
  }
}

@media (max-width: 1279px) {
  .categories-list {
    column-count: 3;
  }
}

@media (max-width: 1023px) {
  .categories-list {
    column-count: 2;
  }
}

@media (max-width: 767px) {
  .categories-list {
    column-gap: 8px;
  }
}

@media (max-width: 479px) {
  .categories-list {
    column-count: 1;
  }
}

.categories-list__i {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
  padding: 4px 0 4px 24px;
  box-sizing: border-box;
  position: relative;
  font-size: 16px;
  line-height: 19px;
}

@media (max-width: 767px) {
  .categories-list__i {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 479px) {
  .categories-list__i {
    font-size: 16px;
    line-height: 19px;
  }
}

.categories-list__i:before {
  background: url(../icons/5.svg) no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 14px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: background 0.2s;
}

.categories-list__i a {
  flex-shrink: 1;
  min-width: 0;
  position: relative;
  font-weight: 500;
  color: #323650;
  text-transform: uppercase;
}

.categories-list__i a span {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.categories-list__i i {
  flex-shrink: 0;
  min-width: 60px;
  height: 26px;
  margin-left: 30px;
  padding: 0 10px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 2px 6px #E8E7EF;
  border-radius: 10px;
  font-size: 12px;
  line-height: 26px;
  color: #4D4D4D;
  font-style: normal;
  text-align: center;
  transition: color 0.2s, background 0.2s;
}

@media (max-width: 767px) {
  .categories-list__i i {
    min-width: 52px;
    height: 24px;
    margin-left: 15px;
    padding: 0 6px;
    line-height: 24px;
  }
}

@media (max-width: 479px) {
  .categories-list__i i {
    min-width: 60px;
    height: 26px;
    margin-left: 30px;
    padding: 0 10px;
    line-height: 26px;
  }
}

.categories-list__i > * {
  flex-grow: 0;
  display: block;
  max-width: 100%;
}

@media (min-width: 1024px) {
  body:not(.mobile) .categories-list__i:hover:before {
    background-image: url(../icons/5_h.svg);
  }
  body:not(.mobile) .categories-list__i:hover a {
    color: #6852D9;
  }
  body:not(.mobile) .categories-list__i:hover i {
    color: #ffffff;
    background: #6852D9;
  }
  body:not(.mobile) .categories-list__i:hover:after {
    opacity: 1;
  }
}

.statistics-table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  font-size: 14px;
  line-height: 16px;
  color: #4D4D4D;
}

@media (max-width: 767px) {
  .statistics-table {
    display: block;
    box-sizing: border-box;
    padding: 0 2px;
    background-color: transparent;
  }
}

@media (max-width: 479px) {
  .statistics-table {
    padding: 0;
  }
}

.statistics-table-w {
  margin: 0 -6px 27px;
  background-color: #ffffff;
  padding-bottom: 26px;
}

@media (max-width: 767px) {
  .statistics-table-w {
    margin: 0 -2px 27px;
    padding: 0;
    background-color: transparent;
  }
}

.statistics-table__head {
  display: table-header-group;
}

@media (max-width: 767px) {
  .statistics-table__head {
    display: none;
  }
}

.statistics-table__row {
  display: table-row;
}

@media (max-width: 767px) {
  .statistics-table__row {
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    width: calc(50% - 1px);
    margin: 2px 0;
    padding: 12px 20px;
    background-color: #ffffff;
    box-sizing: border-box;
    border: 1px solid #CCCBD4;
    border-radius: 10px;
  }
}

@media (max-width: 767px) and (max-width: 479px) {
  .statistics-table__row {
    width: 100%;
    margin: 0;
    border: none;
    border-radius: 0;
  }
  .statistics-table__row:not(:first-child) {
    border-top: 1px solid #22293c;
  }
}

.statistics-table__th {
  display: table-cell;
  padding: 14px 26px;
  vertical-align: middle;
  border-bottom: 1px solid #CCCBD4;
  white-space: nowrap;
}

@media (max-width: 1023px) {
  .statistics-table__th {
    padding: 13px 12px;
  }
}

.statistics-table__th_r {
  text-align: center;
}

.statistics-table__body {
  display: table-row-group;
}

@media (max-width: 767px) {
  .statistics-table__body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  }
}

.statistics-table__td {
  display: table-cell;
  padding: 20px 26px 0;
  vertical-align: top;
  box-sizing: border-box;
}

@media (max-width: 1023px) {
  .statistics-table__td {
    padding: 15px 21px 0;
  }
}

@media (max-width: 767px) {
  .statistics-table__td {
    display: block;
    padding: 0;
    position: relative;
  }
}

.statistics-table__td_m {
  width: 26.2%;
  padding-left: 23px;
}

@media (max-width: 767px) {
  .statistics-table__td_m {
    width: auto;
    min-height: 24px;
    margin-bottom: 11px;
  }
}

.statistics-table__td_m span {
  display: inline-block;
  margin-right: 20px;
  padding: 0 11px 10px 3px;
  white-space: nowrap;
  position: relative;
}

.statistics-table__td_m span:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: url("../icons/underline.svg") center/100% 100%;
  opacity: 0;
  transition: opacity 0.2s, box-shadow 0.2s;
}

@media (max-width: 1023px) {
  .statistics-table__td_m span {
    margin-right: 15px;
    padding: 0;
  }
  .statistics-table__td_m span:before {
    display: none;
  }
}

.statistics-table__td_m span:last-child {
  margin: 0;
}

@media (min-width: 1024px) {
  body:not(.mobile) .statistics-table__td_m span:hover:before {
    opacity: 1;
    box-shadow: 0 2px 8px #A498E1;
  }
  body:not(.mobile) .statistics-table__td_m span:hover i {
    background: url("../icons/14_h.svg") center/contain no-repeat;
  }
}

.statistics-table__td_m i {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 14px;
  margin-right: 10px;
  background: url("../icons/14.svg") center/contain no-repeat;
  transition: background 0.2s;
}

.statistics-table__td_m a {
  vertical-align: middle;
}

.statistics-table__td_n {
  padding-left: 18px;
}

.statistics-table__td_n i {
  width: 10px;
  height: 11px;
  margin-right: 8px;
  background: url("../icons/11.svg") center/contain no-repeat;
  float: left;
}

@media (max-width: 767px) {
  .statistics-table__td_n i {
    display: inline-block;
    margin-right: 9px;
  }
}

.statistics-table__td_n span {
  display: block;
  overflow: hidden;
}

.statistics-table__td_n a {
  color: #4D4D4D;
  background: none;
}

@media (max-width: 767px) {
  .statistics-table__td_n {
    min-height: 24px;
    margin-bottom: 14px;
  }
}

.statistics-table__td_s {
  width: 15.8%;
  padding-left: 23px;
}

@media (max-width: 767px) {
  .statistics-table__td_s {
    width: auto;
    min-height: 24px;
    margin-bottom: 24px;
  }
}

.statistics-table__td_s span {
  display: inline-block;
  padding: 0 7px 10px;
  white-space: nowrap;
  position: relative;
}

.statistics-table__td_s span:before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: url("../icons/underline.svg") center/100% 100%;
  opacity: 0;
  transition: opacity 0.2s, box-shadow 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .statistics-table__td_s span:hover:before {
    opacity: 1;
    box-shadow: 0 2px 8px #A498E1;
  }
  body:not(.mobile) .statistics-table__td_s span:hover i {
    background: url("../icons/5_h.svg") center/contain no-repeat;
  }
}

@media (max-width: 1023px) {
  .statistics-table__td_s span {
    padding: 0;
  }
  .statistics-table__td_s span:before {
    display: none;
  }
}

.statistics-table__td_s i {
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 8px;
  margin-right: 7px;
  background: url("../icons/5.svg") center/contain no-repeat;
  transition: background 0.2s;
}

.statistics-table__td_s a {
  vertical-align: middle;
}

.statistics-table__td_l {
  width: 8%;
}

@media (max-width: 767px) {
  .statistics-table__td_l {
    display: inline-block;
    width: auto;
    min-height: 21px;
    margin-right: 40px;
  }
}

.statistics-table__td_l span {
  display: inline-block;
  white-space: nowrap;
}

.statistics-table__td_l i {
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 13px;
  margin-right: 9px;
  background: url("../icons/13.svg") center/contain no-repeat;
}

@media (max-width: 767px) {
  .statistics-table__td_l i {
    display: inline-block;
    margin-right: 9px;
  }
}

.statistics-table__td_r {
  width: 8%;
  color: #FF4652;
  text-align: center;
}

@media (max-width: 767px) {
  .statistics-table__td_r {
    display: inline-block;
    width: auto;
  }
}

.statistics-table__td_r span {
  display: inline-block;
  white-space: nowrap;
}

.statistics-table__td_r i {
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 13px;
  margin-right: 12px;
  background: url("../icons/15.svg") center/contain no-repeat;
}

@media (max-width: 767px) {
  .statistics-table__td_r i {
    display: inline-block;
    margin-right: 9px;
  }
}

.spec-img {
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;
}

.spec-img-list {
  margin: 0 -54px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

@media (max-width: 1023px) {
  .spec-img-list {
    margin: 0 -25px;
  }
}

@media (max-width: 767px) {
  .spec-img-list {
    margin: 0;
  }
}

.spec-img a {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 300px;
  height: 250px;
  overflow: hidden;
  margin: 10px 54px;
}

@media (max-width: 1023px) {
  .spec-img a {
    margin: 10px 25px;
  }
}

@media (max-width: 767px) {
  .spec-img a {
    margin: 10px;
  }
}

@media (max-width: 479px) {
  .spec-img a {
    margin: 10px 0;
  }
}

.spec-img a img {
  display: block;
  width: 100%;
}

.spec-img_v a {
  height: 100px;
}

.upload {
  margin-bottom: 140px;
}

@media (max-width: 767px) {
  .upload {
    margin-bottom: 60px;
  }
}

.upload .h {
  margin-bottom: 2px;
}

.upload-block {
  position: relative;
  min-height: 100px;
  margin: 2px -6px 20px;
  padding: 3% 8%;
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .upload-block {
    margin: 2px -2px 20px;
  }
}

.upload-block-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.upload-block-form-btn {
  padding-top: 53px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4D4D4D;
  cursor: pointer;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s;
}

.upload-block-form-btn:before {
  content: '';
  display: block;
  width: 48px;
  height: 38px;
  background: url("../icons/upload.svg") no-repeat;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 1024px) {
  body:not(.mobile) .upload-block-form-btn:hover {
    color: #6852D9;
  }
}

.upload-block-form p {
  margin: 7px 0 0;
  line-height: 16px;
}

.upload > p {
  margin: 20px 0;
}

.upload-fields {
  margin-bottom: 28px;
}

.upload-fields__el {
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1023px) {
  .upload-fields__el {
    flex-direction: column;
    align-items: flex-start;
  }
}

.upload-fields__el-input {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 450px;
}

.upload-fields__el-input_t {
  max-width: 671px;
  margin-bottom: 34px;
}

.upload-fields__el-input_v {
  max-width: 671px;
}

.upload-fields__el-input input, .upload-fields__el-input textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  padding: 0 20px;
  background-color: #fff;
  border: 1px solid #CCCBD4;
  border-radius: 10px;
  font: 12px "Roboto", sans-serif;
  color: #4D4D4D;
  text-transform: uppercase;
  transition: border-color 0.2s;
}

.upload-fields__el-input input::-webkit-input-placeholder, .upload-fields__el-input textarea::-webkit-input-placeholder {
  color: #4D4D4D;
}

.upload-fields__el-input input::-moz-placeholder, .upload-fields__el-input textarea::-moz-placeholder {
  color: #4D4D4D;
}

.upload-fields__el-input input:-moz-placeholder, .upload-fields__el-input textarea:-moz-placeholder {
  color: #4D4D4D;
}

.upload-fields__el-input input:-ms-input-placeholder, .upload-fields__el-input textarea:-ms-input-placeholder {
  color: #4D4D4D;
}

.upload-fields__el-input input:focus, .upload-fields__el-input textarea:focus {
  border-color: #807f88;
}

.upload-fields__el-input input:focus::-webkit-input-placeholder, .upload-fields__el-input textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.upload-fields__el-input input:focus::-moz-placeholder, .upload-fields__el-input textarea:focus::-moz-placeholder {
  color: transparent;
}

.upload-fields__el-input input:focus:-moz-placeholder, .upload-fields__el-input textarea:focus:-moz-placeholder {
  color: transparent;
}

.upload-fields__el-input input:focus:-ms-input-placeholder, .upload-fields__el-input textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.upload-fields__el-input textarea {
  height: 183px;
  padding: 17px 20px;
  resize: none;
  overflow: auto;
}

.upload-fields__el-n {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
  box-sizing: border-box;
  padding: 5px 17px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.upload-fields__el-n_er {
  color: #FF4652;
}

.upload-fields__el_btn {
  max-width: 671px;
  margin: 8px 0 27px;
  justify-content: flex-end;
}

.upload-fields-save {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  min-width: 100px;
  height: 40px;
  padding: 0 20px;
  border: none;
  background: #fff;
  border-radius: 10px;
  font-size: 14px;
  color: #4D4D4D;
  box-shadow: 0 2px 6px #E8E7EF;
  transition: background 0.2s, color 0.2s;
}

@media (min-width: 1024px) {
  body:not(.mobile) .upload-fields-save:hover {
    color: #fff;
    background: #6852D9;
  }
}

.upload-fields-save span {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload-fields > p {
  max-width: 671px;
}

.upload .message_error {
  color: #18ade9;
}

.upload-active-image {
  width: 25%;
  float: left;
  margin: 0 20px 0 0;
}

.upload-active-image > div {
  position: relative;
  padding: 56.25% 0 0 0;
  background: #fff;
}

.upload-active-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.upload-active-data {
  overflow: hidden;
}

.upload-active-status {
  font-weight: 700;
}

.upload-active-bar {
  height: 10px;
  margin: 30px 0;
  background: #DCDCDC;
  border-radius: 2px;
}

.upload-active-bar span {
  height: 10px;
  display: block;
  background: linear-gradient(90deg, #6852D9 0%, #FF4652 100%);
  border-radius: 2px;
}

.text-success {
  color: #4D4D4D;
}

.text-danger {
  color: #4D4D4D;
}

#upload_success .upload-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.w {
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

@media screen and (min-width: 0 \0 ) and (min-resolution: 72dpi) {
  .w {
    height: 100%;
  }
}

.w > * {
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 0;
}

.w .page {
  width: 100%;
  max-width: 1604px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 4px 6px 1px;
}

@media (max-width: 767px) {
  .w .page {
    padding: 13px 2px 1px;
    border-top: 4px solid #C4C4C4;
  }
}
